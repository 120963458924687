import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import useAxiosFunction from '../../../hooks/useAxiosFunction';

import Logo from '../../../assets/imgs/logos/Platform360.png';

import * as S from '../login/styles';

const Password: React.FC<{}> = () => {
    const [passwordInput, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const navigate = useNavigate();

    const { token } = useParams<{ token: string }>();

    const { response, error, axiosFetch } = useAxiosFunction();
    const axiosFetchLogout = useAxiosFunction().axiosFetch;

    const initialCall = async () => {
        await axiosFetchLogout({ url: '/api/auth/logout', method: 'get' });
    };

    const requestLogin = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (passwordConfirmation === '' || passwordInput === '') {
            return;
        }

        if (passwordConfirmation === passwordInput) {
            await axiosFetch({
                url: '/api/auth/setPassword',
                method: 'put',
                data: {
                    password: passwordInput,
                    token
                }
            });
        }
    };

    useEffect(() => {
        if (response) {
            navigate('/');
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setPassword('');
            setPasswordConfirmation('');
        }
    }, [error]);

    useEffect(() => {
        initialCall();
    }, []);

    return (
        <S.Container>
            <S.Box elevation={3}>
                <Box display="flex" justifyContent="center" sx={{ width: '100%', height: '20%' }}>
                    <S.Logo src={Logo} alt="Logo Platform" />
                </Box>

                <S.Stack spacing={2}>
                    <S.Form onSubmit={(e) => { requestLogin(e); }}>
                        <S.Stack spacing={2} $fullWidth>
                            <S.TextField
                                required
                                id="login-email-input"
                                label="Password"
                                variant="outlined"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <S.TextField
                                required
                                id="login-password-input"
                                label="Repeat password"
                                variant="outlined"
                                type="password"
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                            />
                        </S.Stack>
                        <S.Button
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon />}
                        >
                            Login
                        </S.Button>
                    </S.Form>
                </S.Stack>
            </S.Box>
        </S.Container>
    );
};

export default Password;
