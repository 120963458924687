import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FolderIcon from '@mui/icons-material/Folder';

import useAxios from '../../../hooks/useAxios';

import { StyledPathContainer, StyledPathLink, StylePathLinkText } from './styles';

export interface IProps {
    root?: boolean;
}

const Path: React.FC<IProps> = ({ root }) => {
    const { folderId } = useParams();

    const { response: responsePath, refetch } = useAxios({
        url: folderId ? `/api/docs/${folderId}/path` : undefined,
        method: 'get'
    });

    useEffect(() => {
        refetch();
    }, [folderId]);

    return (
        <div style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1em'
        }}
        >

            <StyledPathContainer>
                <StyledPathLink
                    to="/documentacion/"
                >
                    <StylePathLinkText variant="h6">
                        Documentación
                        <br />
                    </StylePathLinkText>
                </StyledPathLink>
                { !root && responsePath && responsePath.length > 0 && responsePath.map(
                    (path: any, index: number) => (
                        <Box display="block">
                            <StyledPathLink
                                key={path.id}
                                to={`/documentacion/${path.id}`}
                            >
                                <StylePathLinkText
                                    variant="h6"
                                    style={{
                                        marginLeft: `${20 * (path.depth + 1)}px`
                                    }}
                                >
                                    {' '}
                                    <span className="material-icons" style={{ color: 'primary' }}>subdirectory_arrow_right</span>
                                    <FolderIcon color="primary" sx={{ fontSize: 23, alignItems: 'stretch' }} />
                                    {' '}
                                    { index === responsePath.length - 1
                                        ? path.name
                                        : `${path.name}`}
                                </StylePathLinkText>
                            </StyledPathLink>
                        </Box>
                    )
                ) }
            </StyledPathContainer>
        </div>
    );
};

export default Path;
