import React from 'react';

import {
    StyledTextBox, StyledHeaderBox, StyledDocContainer, StyledActionBox, StyledDateBox
} from './styles';

const Header: React.FC = () => (
    <>
        <StyledDocContainer style={{
            backgroundColor: '#7C7C7C', color: 'white', width: '93%'
        }}
        >
            <StyledHeaderBox>
                <StyledTextBox style={{ marginLeft: '3em', color: 'white' }}>
                    <strong>Nombre</strong>
                </StyledTextBox>
            </StyledHeaderBox>
            <StyledDateBox sx={{ display: { xs: 'none', sm: 'inline-block' }, marginRight: '2rem' }}>
                <strong style={{ marginRight: '5em' }}>
                    Fecha
                </strong>
            </StyledDateBox>
            <StyledActionBox />
        </StyledDocContainer>
    </>
);

export default Header;
