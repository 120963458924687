import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Person from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

import axiosInstance from '../../../utils/axiosIntercepter';

export interface IProps {
    colapseHandler?: () => void | undefined;
}

const Topbar: React.FC<IProps> = ({ colapseHandler }: IProps) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const callBase = async () => {
        await axiosInstance.get('/base');
    };

    const logoutCall = async () => {
        await axiosInstance.get('/api/auth/logout');
        await callBase();
    };

    const exitPlatformCall = async () => {
        await axiosInstance.get('/api/auth/home');
        await callBase();
    };

    return (
        <div style={{ backgroundColor: '#dadde0' }}>
            <AppBar
                position="static"
                sx={{
                    borderBottomRightRadius: '3rem 4rem',
                    backgroundColor: '#707070'
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1 }}>
                            {colapseHandler && (
                                <IconButton
                                    onClick={colapseHandler}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                        color: 'white'
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>

                            )}

                        </Box>
                        <Box sx={{ flexGrow: 0, marginRight: '1rem' }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ bgcolor: 'lightgray' }}>
                                        <Person />
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key="setting-platforms-exit" onClick={exitPlatformCall}>
                                    <Typography textAlign="center">Platforms</Typography>
                                </MenuItem>
                                <MenuItem key="setting-platforms-logout" onClick={logoutCall}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

export default Topbar;
