import styled from 'styled-components';

export const IconButton = styled.button.attrs(
    () => ({
        type: 'button'
    })
)<{}>`
    width: 2em;
    height: inherit;
    margin: 0 0.5em;
    background: transparent;
    border: none;
    color: ${(props) => (props.color ? props.color : props.theme.colors.terciary)};
    cursor: pointer;
    & span::before {
    font-size: 1em;
    }
`;

export const IconAddButton = styled(IconButton)<{}>`
`;

export const IconCancelButton = styled(IconButton)<{}>`
    color: ${(props) => (props.theme.colors.buttons.danger)};
`;
// height: 2rem;
// margin-top: 3rem;
// display: flex;
// align-items: right;
