import React, { useMemo, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ResetFlagContext } from '.';
import useAxios from '../../../../../hooks/useAxios';

interface IFilterProps {
    apiName: string,
    index: number,
    relatedWith: string,
    onChange: (filterValue: string, index: number | string) => void,
}

const RelationFilter: React.FC<IFilterProps> = ({
    relatedWith, apiName, index, onChange
}) => {
    const resetFlag: number = useContext(ResetFlagContext);
    const [filterValue, setFilterValue] = React.useState<string>('all');

    const { response: optionsResponse } = useAxios({
        url: `/api/structures/${relatedWith}/filter`,
        method: 'get'
    });

    const filterOptions: Array<{ value: string, text: string }> | undefined = useMemo(() => {
        if (!optionsResponse || !optionsResponse.options) { return undefined; }
        if (!Array.isArray(optionsResponse.options)) { return undefined; }

        return optionsResponse.options;
    }, [optionsResponse]);

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value }
        } = event;
        setFilterValue(value || 'all');

        if (value === 'all') {
            onChange('', `rel_${index}`);
        } else {
            onChange(`${apiName}_${value}`, `rel_${index}`);
        }
    };

    useEffect(() => {
        if (resetFlag) {
            setFilterValue('');
            onChange('', `rel_${index}`);
        }
    }, [resetFlag]);

    return (
        <>
            {filterOptions && filterOptions.length > 0 && (
                <Box sx={{
                    p: 2,
                    border: '2px solid lightgray',
                    borderRadius: '1rem',
                    display: 'flex',
                    gap: '1rem'
                }}
                >
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel id={`relation-filter-${apiName}-label`}>{apiName}</InputLabel>
                        <Select
                            labelId={`relation-filter-${apiName}-label`}
                            id={`relation-filter-${apiName}-label`}
                            value={filterValue}
                            onChange={handleChange}
                            input={<OutlinedInput label={apiName} />}
                        >

                            <MenuItem value="all">
                                <em>Todos</em>
                            </MenuItem>
                            {filterOptions.map((option: { value: string, text: string }) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <ListItemText primary={option.text} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}
        </>
    );
};

export default RelationFilter;
