import { createContext, useContext } from 'react';

export type URIContextType = {
    baseURI: string;
}

export type APIURIContextType = {
    APIURI: string;
}

export const URIContext = createContext<URIContextType>({
    baseURI: ''
});

export const useURIContext = () => useContext(URIContext);

const APIURI: string = (process.env.REACT_APP_BASE_URI_API) ? process.env.REACT_APP_BASE_URI_API : 'http://localhost:4000';

export const APIURIContext = createContext<APIURIContextType>({
    APIURI
});
