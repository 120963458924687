import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledContentBox = styled(Box)<BoxProps>(() => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative'

}));
export const StyledHeaderContainer = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    width: '95%',
    height: '5.33rem',
    borderRadius: '0 0 3rem 0',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: '2rem',
    marginRight: '10%'
}));

export const StyledMainContainer = styled(Box)<BoxProps>(() => ({
    flexGrow: '1',
    overflow: 'auto',
    marginTop: '5.2rem'

}));
