import React, { startTransition } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { IField, IParams } from '../../../../../../types/bbdd';

import { SpecialFieldBox, StyledOptionRow } from './styles';

export interface IProps {
    field: IField;
    onChange: (params: IParams) => void;
}

const Selector: React.FC<IProps> = ({ field, onChange }) => {
    const handleAppendValue = () => {
        onChange({
            ...field.params,
            selectorOptions: [...(field.params?.selectorOptions || []), 'NewValue']
        });
    };

    const handleRemoveOption = (index: number) => {
        const values = field.params?.selectorOptions;
        if (values) {
            values.splice(index, 1);

            onChange({
                ...field.params,
                selectorOptions: [...values]
            });
        }
    };

    const handleChangeValue = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        const values = field.params?.selectorOptions;
        if (values) {
            startTransition(() => {
                values[index] = e.target.value;
            });
        }
    };

    return (
        <SpecialFieldBox>
            {field.params?.selectorOptions?.map((value: string, index: number) => (
                <StyledOptionRow key={`${value}-${index.toString()}`}>
                    <Box flexGrow={1}>
                        <TextField
                            key={`option-${index.toString()}`}
                            variant="outlined"
                            fullWidth
                            name={`option-${index}`}
                            defaultValue={value}
                            onChange={(e) => { handleChangeValue(e, index); }}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={() => { handleRemoveOption(index); }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </StyledOptionRow>
            ))}

            <Button
                variant="contained"
                onClick={handleAppendValue}
            >
                Añadir opción
            </Button>
        </SpecialFieldBox>
    );
};

export default Selector;
