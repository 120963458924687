import React, { useState, startTransition } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import TextField from '@mui/material/TextField';

import Modal, { IProps as ModalProps } from '../../sideModal';
import { IPlat } from '../../../../pages/platforms';

import StyledContainer from './styles';

export interface IProps extends Omit<ModalProps, 'onSubmit'> {
    onSubmit: (platform: IPlat) => void;
    platform: IPlat;
}

const Form: React.FC<IProps> = ({ platform, onSubmit, ...rest }) => {
    const [plat, setPlat] = useState<IPlat>(platform);
    const handleSubmit = () => {
        onSubmit(plat);
    };

    const handleChangeName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setPlat((prev: IPlat) => ({ ...prev, name: e.target.value }));
        });
    };

    const handleChangeSubtitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setPlat((prev: IPlat) => ({ ...prev, subtitle: e.target.value }));
        });
    };

    const handleChangeDescription = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setPlat((prev: IPlat) => ({ ...prev, description: e.target.value }));
        });
    };

    const handleChangeIsHidden = () => {
        startTransition(() => {
            setPlat((prev: IPlat) => ({ ...prev, isHidden: !prev.isHidden }));
        });
    };

    return (
        <Modal
            onSubmit={handleSubmit}
            {...rest}
        >
            <StyledContainer>
                <TextField
                    variant="outlined"
                    name="name"
                    label="name"
                    fullWidth
                    defaultValue={plat.name}
                    onChange={(e) => { handleChangeName(e); }}
                />
                <TextField
                    variant="outlined"
                    spellCheck
                    name="subtitle"
                    label="Subtitle"
                    fullWidth
                    defaultValue={plat.subtitle}
                    onChange={(e) => { handleChangeSubtitle(e); }}
                />

                <TextField
                    variant="outlined"
                    spellCheck
                    name="description"
                    label="Description"
                    fullWidth
                    defaultValue={plat.description}
                    onChange={(e) => { handleChangeDescription(e); }}
                />

                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Switch
                                name="hidden"
                                defaultChecked={platform.isHidden}
                                onChange={() => handleChangeIsHidden()}
                            />
                        )}
                        label="Oculto"
                    />
                </FormGroup>
            </StyledContainer>
        </Modal>
    );
};

export default Form;
