import styled from 'styled-components';
// import Panel from '../../components/atoms/structure/panel/styles';

export const Page = styled.div`
    height: 94vh;
    background-color: #dadde0;
    display: flex;
    flex-direction: column;
`;

export const Head = styled.div`
    padding: 3em;
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: white;
    border-bottom-right-radius: 8rem 5rem
`;

export const Title = styled.h1`
    padding: 20;
    letter-spacing: 0.1em;
    line-height: 1.3em;
    margin-left: 0.5em;
    margin-top: 0.1px;
    font-size: 16px;
    height: 1em;
    width: 20em;
`;

export const Browser = styled.input`
    display: "flex";
    alignSelf: "center";
    justifyContent: "center";
    flexDirection: "column";
    padding: 30;
    position: 'fixed';
    width: 70vw;
    height: 50vh;
`;

export const SearchBar = styled.div`
    display: "flex";
    flexDirection: "row";
    justifyContent: "space-between";
    width: 60vh;
`;

export const Container = styled.div`
    padding: 1em;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    gap: 1em;
    width: 100%;
    margin-top: -1rem;
    background-color: #dadde0;
    overflow: auto;
`;

export const PopupOpenTrigger = styled.div`
    position: absolute;
    top: 9vh;
    right: 3vw;
`;

export const AddButtonPanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1em;
    padding: 1em;
    box-shadow: 0.3em 1em 1em lightgray;
    margin: 10px;
    background-color: white;
    height: 25rem;
`;
