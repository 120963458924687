import React, { useState, useEffect, startTransition } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
// import { Typography } from '@mui/material';

import useAxiosFunction from '../../../hooks/useAxiosFunction';

import * as S from './styles';

const ResetPassword: React.FC<{}> = () => {
    const [newPass, setNewPass] = useState<string>();
    const [confirmPass, setConfirmPass] = useState<string>();
    const navigate = useNavigate();

    const { token } = useParams<{ token: string }>();
    const { email } = useParams<{ email: string }>();

    const { axiosFetch: fetchPassword, error } = useAxiosFunction();

    const changePassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setNewPass(() => e.target.value);
        });
    };

    const confirmPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setConfirmPass(e.target.value);
            setConfirmPass(() => e.target.value);
        });
    };

    const handleChangePass = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            await fetchPassword({
                url: '/api/auth/resetPassword',
                method: 'put',
                data: {
                    confirmPassword: confirmPass,
                    newPassword: newPass,
                    token
                }
            });
            navigate('/');
        } catch (err) {
            let message = 'Error';
            if (err instanceof Error) {
                message = err.message;
            }
            console.warn(message);
        }
    };
    console.warn(`${email}ff`);

    function equalPasswords() {
        if (newPass === confirmPass && (newPass !== '' && confirmPass !== '')) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (error) {
            setNewPass('');
            setConfirmPass('');
        }
    }, [error]);

    return (
        <S.Container>
            <S.Box elevation={4}>
                <S.Title>
                    FORMULARIO DE RESETEO DE CONTRASEÑA
                </S.Title>
                <S.Stack spacing={4}>
                    <S.Form>
                        <S.Stack spacing={2} $fullWidth>
                            <S.Subtitle>
                                En este formulario cambiarás la contraseña de tu usuario.
                                La contraseña ha de tener, como mínimo, 8 caracteres,
                                conteniendo al menos una letra mayúscula y un número.
                            </S.Subtitle>
                            <S.TextField
                                required
                                id="login-email-input"
                                label="Password"
                                variant="outlined"
                                type="password"
                                onChange={(e) => { changePassword(e); }}
                            />
                            <S.TextField
                                required
                                id="login-password-input"
                                label="Repeat password"
                                variant="outlined"
                                type="password"
                                onChange={(e) => { confirmPassword(e); }}
                            />
                        </S.Stack>
                        <S.Button
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon />}
                            disabled={!equalPasswords()}
                            onClick={handleChangePass}
                        >
                            Establecer nueva contraseña
                        </S.Button>
                    </S.Form>
                </S.Stack>
            </S.Box>
        </S.Container>
    );
};

export default ResetPassword;
