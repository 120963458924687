import React, { useState, useContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

import { UserContext } from '../../../App';

import useAxiosFunction from '../../../hooks/useAxiosFunction';
// import DataEntry, { IDataEntryDocument } from '../models/DataEntry.model';

import {
    StyledTextBox, StyledFolderLink, StyledDocContainer, StyledActionBox, StyledDateBox,
    ResponsiveDocs
} from './styles';

export interface IProps {
    folderId: string;
    // userId: string;
    folderName: string;
    onUpdate: () => void;
    createdAt?: Date;
    // updatedAt?: Date;
}

const Folder: React.FC<IProps> = ({
    folderId, folderName, onUpdate, createdAt
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const { platform } = useContext(UserContext);

    const { axiosFetch: removeAxiosFunction } = useAxiosFunction();
    const handleDelete = async () => {
        if (platform) {
            await removeAxiosFunction({
                url: `/api/platforms/${platform}/folders/${folderId}`,
                method: 'delete'
            });
            onUpdate();
        }
        setOpen(false);
    };

    return (
        <ResponsiveDocs>
            <StyledDocContainer>
                <StyledFolderLink to={`/documentacion/${folderId}`}>
                    <StyledTextBox>
                        <FolderIcon color="primary" />
                        <strong style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        >
                            {folderName}

                        </strong>
                    </StyledTextBox>
                </StyledFolderLink>
                <StyledDateBox sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                    <strong style={{ marginRight: '2em' }}>
                        {`${createdAt?.toString().slice(8, 10)}/${createdAt?.toString().slice(5, 7)}/${createdAt?.toString().slice(0, 4)}`}
                    </strong>
                </StyledDateBox>
                <StyledActionBox>
                    <IconButton component="label" onClick={() => { setOpen(true); }}>
                        <DeleteIcon />
                    </IconButton>
                </StyledActionBox>
            </StyledDocContainer>
            <Dialog open={open} onClose={() => { setOpen(false); }}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <strong> Atención ! </strong>
                        Todos los contenidos de la
                        carpeta serán eliminados. ¿ Está seguro que desea continuar ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpen(false); }}>Cancelar</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </ResponsiveDocs>
    );
};
export default Folder;
