import React, { useState, useEffect } from 'react';
import Form from '.';
import { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { IPlat } from '../../../../pages/platforms';

export interface IProps extends ModalProps {
    id: string;
}

const EditForm: React.FC<ModalProps> = ({ onSubmit, id, ...rest }) => {
    const [platform, setPlatform] = useState<IPlat>();

    const {
        response, loading, error, axiosFetch
    } = useAxiosFunction();

    const {
        axiosFetch: postAxiosFetch
    } = useAxiosFunction();
    const onSubmitNewAction = async (editedPlatform: IPlat) => {
        try {
            await postAxiosFetch({
                url: `/api/platforms/${id}`,
                method: 'put',
                data: { ...editedPlatform }
            });
            onSubmit?.();
        } catch (err) {
            console.warn(err.message);
        }
    };

    useEffect(() => {
        axiosFetch({ url: `/api/platforms/${id}`, method: 'get' });
    }, []);

    useEffect(() => {
        setPlatform(response);
    }, [response]);

    return (
        <>
            {!loading && !error && platform && (
                <Form
                    onSubmit={onSubmitNewAction}
                    platform={platform}
                    {...rest}
                />
            )}
        </>
    );
};

export default EditForm;
