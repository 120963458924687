import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Submenus, { IProps as ISidebarProps } from './submenus';
import Visualization from './visualization';
import UserProperties from './userProperties';

interface IProps extends ISidebarProps { }

const Config: React.FC<IProps> = ({ sidebarUpdater }: IProps) => (
    <Box p="2rem" width="100%">
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Visualization />
            </Grid>
            <Grid item xs={12} md={6}>
                <UserProperties />
            </Grid>
            <Grid item xs={12}>
                <Submenus sidebarUpdater={sidebarUpdater} />
            </Grid>
        </Grid>
    </Box>
);

export default Config;
