/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { alpha } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Switch from '@mui/material/Switch';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from 'luxon';

import useUploadFile from '../hooks/useUploadFile';
import useAxiosFunction from '../hooks/useAxiosFunction';
import axios from './axiosIntercepter';

import { posibleType, IParams } from '../types/bbdd';

export interface IProps {
    initialValue: posibleType;
    fieldType: string;
    fieldName: string;
    structureId: string;
    params?: IParams;
    setter: (value: any) => void;
}

const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: (value: any) => void
) => {
    setter(event.target.value);
};

const handleNumberInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: (value: any) => void
) => {
    setter(parseFloat(event.target.value));
};

const handleBooleanInputChange = (
    prevValue: boolean,
    setter: (value: any) => void
) => {
    if (prevValue) {
        setter(false);
    } else {
        setter(true);
    }
};

const handleDateChange = (
    date: DateTime | null,
    setter: (value: any) => void
) => {
    if (!date || !date.isValid) { setter(undefined); return; }
    setter(date.toJSDate());
};

const handleSelectInputValue = (
    event: SelectChangeEvent,
    setter: (value: any) => void
) => {
    setter(event.target.value);
};

interface IPropsFileField {
    initialValue: string;
    fieldName: string;
    structureId: string;
    setter: (value: any) => void;
}

const FileField: React.FC<IPropsFileField> = ({
    initialValue,
    setter,
    fieldName,
    structureId
}) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [prevId, setPrevId] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();

    const { response, uploadFunction } = useUploadFile();
    const { response: getResponse, axiosFetch: getAxiosFetch } = useAxiosFunction();

    const handleUpload = async () => {
        if (selectedFile) {
            await uploadFunction({
                file: selectedFile,
                url: `/api/structures/${structureId}/attachements`
            });
        }
    };

    const handleChangeFile = async (event: any) => {
        setSelectedFile(() => event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const getPresentFileName = async () => {
        await getAxiosFetch({
            url: `/api/structures/${structureId}/attachements/${initialValue}`,
            method: 'get'
        });
    };

    const handleDownloadFile = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `/api/structures/${structureId}/attachements/${prevId}?q=download`,
                responseType: 'blob',
                headers: {}
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName || 'file');
            document.body.appendChild(link);
            link.click();
        } catch {
            console.warn('Error downloading');
        }
    };

    const handleRemoveFile = async () => {
        setPrevId(undefined);
        setSelectedFile(undefined);
        setFileName(undefined);
        setter(null);
    };

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
    }, [selectedFile]);

    useEffect(() => {
        if (response) {
            setPrevId(response._id);
            setter(response._id);
        }
    }, [response]);

    useEffect(() => {
        if (getResponse) {
            setFileName(getResponse.name);
        }
    }, [getResponse]);

    useEffect(() => {
        if (initialValue) {
            setPrevId(initialValue);
            getPresentFileName();
        }
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                padding: '1rem',
                borderRadius: '1rem'
            }}
        >
            <InputLabel id={fieldName}>
                {fieldName}
            </InputLabel>
            {`Nombre: ${fileName || ''}`}
            <Box sx={{
                mt: 1,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1
            }}
            >
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <>
                        <span>Añadir archivo</span>
                        <CloudUploadIcon />
                        <input
                            type="file"
                            onChange={handleChangeFile}
                            hidden
                        />
                    </>
                </Button>
                {prevId && (
                    <>
                        <Button
                            onClick={handleDownloadFile}
                            variant="contained"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <span>Decargar</span>
                            <BrowserUpdatedIcon />
                        </Button>
                        <Button
                            onClick={handleRemoveFile}
                            variant="contained"
                            color="error"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <span>Eliminar</span>
                            <DeleteIcon />
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
};

const RenderField: React.FC<IProps> = ({
    initialValue, fieldType, fieldName, setter, params, structureId
}: IProps) => {
    if (fieldType === 'string') {
        return (
            <TextField
                sx={{
                    marginBottom: 1
                }}
                name={fieldName}
                label={fieldName}
                variant="outlined"
                value={initialValue}
                onChange={(
                    event
                ) => handleTextInputChange(
                    event,
                    setter
                )}
            />
        );
    }

    if (fieldType === 'selector') {
        const AddPresentNonOptionValue = () => {
            if (initialValue
                && !params?.selectorOptions?.includes(initialValue as string)
            ) {
                return (
                    <MenuItem
                        value={initialValue as string}
                    >
                        {initialValue as string}
                    </MenuItem>
                );
            }

            return undefined;
        };
        return (
            <>
                {params && params.selectorOptions && (
                    <>
                        <InputLabel id={fieldName}>
                            {fieldName}
                        </InputLabel>
                        <Select
                            labelId={fieldName}
                            value={initialValue ? initialValue as string : ''}
                            onChange={(
                                e: SelectChangeEvent
                            ) => {
                                handleSelectInputValue(e, setter);
                            }}
                        >
                            {AddPresentNonOptionValue()}
                            {params.selectorOptions.map((
                                option, index: number
                            ) => (
                                <MenuItem
                                    key={`${option}-${index.toString()}`}
                                    value={option}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
            </>
        );
    }

    if (fieldType === 'number' || fieldType === 'percentage') {
        return (
            <TextField
                sx={{
                    marginBottom: 1
                }}
                name={fieldName}
                label={fieldName}
                variant="outlined"
                value={(initialValue === 0 || initialValue) ? initialValue : ''}
                type="number"
                InputProps={{
                    endAdornment: fieldType === 'percentage' ? <InputAdornment position="end"> % </InputAdornment> : <></>
                }}
                onChange={(
                    event
                ) => handleNumberInputChange(
                    event,
                    setter
                )}
            />
        );
    }

    if (fieldType === 'boolean') {
        return (
            <FormGroup>
                <FormControlLabel
                    control={(
                        <Switch
                            name="hidden"
                            checked={!!initialValue}
                            onChange={
                                () => handleBooleanInputChange(!!initialValue, setter)
                            }
                        />
                    )}
                    label={fieldName}
                />
            </FormGroup>

        );
    }

    if (fieldType === 'file') {
        return (
            <FileField
                initialValue={initialValue as string}
                setter={setter}
                fieldName={fieldName}
                structureId={structureId}
            />
        );
    }

    if (fieldType === 'datetime') {
        return (
            <DateTimePicker
                label={fieldName}
                inputFormat="dd/MM/yyyy HH:mm"
                value={initialValue ? initialValue as string : null}
                onChange={(date: DateTime | null) => {
                    handleDateChange(date, setter);
                }}
                renderInput={(par: any) => (<TextField {...par} />)}
            />
        );
    }

    if (fieldType === 'date') {
        return (
            <DesktopDatePicker
                label={fieldName}
                inputFormat="dd/MM/yyyy"
                value={initialValue ? initialValue as string : null}
                onChange={(date: DateTime | null) => handleDateChange(date, setter)}
                renderInput={(par: any) => (<TextField readOnly {...par} />)}
            />
        );
    }

    if (fieldType === 'time') {
        return (
            <TimePicker
                label={fieldName}
                inputFormat="HH:mm"
                value={initialValue ? initialValue as string : null}
                onChange={(date: DateTime | null) => handleDateChange(date, setter)}
                renderInput={(par: any) => (<TextField readOnly {...par} />)}
            />
        );
    }

    if (fieldType === 'autonumeric') {
        return (

            <Box sx={{
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                padding: '1rem',
                borderRadius: '1rem'
            }}
            >
                <>
                    <InputLabel sx={{ marginBottom: '0.5rem' }}>
                        {fieldName}
                        {' : '}
                    </InputLabel>
                    {initialValue}
                </>

            </Box>
        );
    }

    return (<></>);
};

export default RenderField;
