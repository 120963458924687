import React, {
    useState, useContext, useEffect
} from 'react';

import Selector from './Selector';
import Indicator from './Indicator';

import {
    IBBDD, IRelation, ISimpleEntry, IEmptySimpleEntry
} from '../../../../../types/bbdd';

import useAxios from '../../../../../hooks/useAxios';
import { StructureContext } from '../../table';

import { StyledRelationContainer, StyledRelationHeading } from './styles';

export interface IProps {
    apiName: string;
    entry: ISimpleEntry | IEmptySimpleEntry;
    onChange: (value: Array<string>) => void;
}
const Relation: React.FC<IProps> = ({ apiName, entry, onChange }) => {
    const [relationInfo, setRelationInfo] = useState<IRelation>();
    const structure: IBBDD | undefined = useContext(StructureContext);

    const {
        response
    } = useAxios({
        url: `${relationInfo
            ? `/api/structures/${relationInfo.relatedWith}/entries/candidates`
            : ''}`,
        method: 'get'
    });

    const handleChangeValue = (modValues: Array<string>) => {
        onChange(modValues);
    };

    const handleRemoveValue = (index: number) => {
        const relatedIds = entry[apiName];

        if (Array.isArray(relatedIds)) {
            relatedIds.splice(index, 1);
            onChange(relatedIds as Array<string>);
        }
    };

    useEffect(() => {
        if (structure) {
            setRelationInfo(() => (
                structure.relations.find(
                    (value: IRelation) => value.apiName === apiName
                )
            ));
        }
    }, [structure]);

    useEffect(() => {
        if (!entry[apiName]) {
            onChange([]);
        }
    }, []);

    return (
        <StyledRelationContainer>
            {response && (
                <StyledRelationHeading variant="h5">
                    {response?.structure}
                </StyledRelationHeading>
            )}
            {response
                && entry[apiName]
                && (entry[apiName] as Array<string>).map((value: string, index: number) => (
                    <Indicator
                        key={value}
                        current={value}
                        options={response.options}
                        disabled={relationInfo?.blockThisSide}
                        onRemove={() => { handleRemoveValue(index); }}

                    />
                ))}
            {relationInfo && (
                <Selector
                    relationValue={entry[apiName] as Array<string>}
                    relation={relationInfo}
                    onChange={handleChangeValue}
                />
            )}
        </StyledRelationContainer>
    );
};

export default Relation;
