import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import ContentHeader from '../../../atoms/structure/contentHeader';
import Row from './Row';
import New from '../form/New';

import { IRole } from '../../../../types/user';
import useAxios from '../../../../hooks/useAxios';

import { StyledTable } from '../../../atoms/structure/table/styles';

const Table: React.FC = () => {
    const [roles, setRoles] = useState<Array<IRole>>();
    const [structures, setStructures] = useState<Array<{_id: string, name: string}>>();

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [open, setOpen] = useState<boolean>(false);

    const {
        response: responseRoles,
        error: errorRoles,
        loading: loadingRoles,
        refetch: refetchRoles
    } = useAxios({
        url: '/api/roles',
        method: 'get'
    });

    const {
        response: responseStructures,
        refetch: refetchStructures
    } = useAxios({
        url: '/api/base?q=short',
        method: 'get'
    });

    const refetch = () => {
        refetchStructures();
        refetchRoles();
    };

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (responseRoles) {
            setRoles(() => responseRoles);
        }
    }, [responseRoles]);

    useEffect(() => {
        if (responseStructures) {
            setStructures(() => responseStructures);
        }
    }, [responseStructures]);

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <ContentHeader
                title="Roles"
                counter={roles ? roles.length : 0}
                buttonText="Nuevo rol"
                onClick={() => { setOpen(true); }}
            />

            <Box sx={{ p: '2rem' }}>
                { !loadingRoles && !errorRoles && (
                    <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>
                        <StyledTable cellSpacing={0} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    { structures && structures.map((str) => (
                                        <TableCell key={`${str._id}-header`}>
                                            <strong>
                                                {str.name}
                                            </strong>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { roles && structures && roles.map((role: IRole) => (
                                    <Row
                                        key={`${role._id}-row`}
                                        roleId={role._id!}
                                        structures={structures}
                                        refetch={refetch}
                                    />
                                )) }

                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                )}
                {roles && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={roles ? roles.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
            { open && (
                <New
                    open={open}
                    onClose={() => { setOpen(false); }}
                    onSubmit={() => { refetch(); setOpen(false); }}
                    title="Editar rol"
                />
            )}
        </Box>
    );
};

export default Table;
