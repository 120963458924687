import React, { useState, useEffect, startTransition } from 'react';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CheckBox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Selector from './specialFields/Selector';
import Formula from './specialFields/Formula';

import { ElementBox, Indicator, MarginBox } from './styles';

import {
    IBaseBBDD, IField, FieldType, IParams
} from '../../../../../types/bbdd';

const selectOptions: Array<{ value: FieldType, text: string }> = [
    {
        value: 'string',
        text: 'Texto'
    }, {
        value: 'number',
        text: 'Número'
    }, {
        value: 'boolean',
        text: 'Boleano'
    }, {
        value: 'percentage',
        text: 'Porcentual'
    }, {
        value: 'datetime',
        text: 'Fecha y hora'
    }, {
        value: 'date',
        text: 'Fecha'
    }, {
        value: 'time',
        text: 'Hora'
    }, {
        value: 'selector',
        text: 'Selector'
    }, {
        value: 'file',
        text: 'Archivo'
    }, {
        value: 'formula',
        text: 'Formula'
    }, {
        value: 'autonumeric',
        text: 'Autonumérico'
    }
];

const allowedToFilter: Array<string> = ['string', 'number', 'boolean', 'date', 'selector'];

export interface IProps {
    field: IField;
    readonly structure: IBaseBBDD;
    onChange: (field: IField) => void;
    onRemove?: () => void;
}

const Field: React.FC<IProps> = ({
    field, structure, onRemove, onChange
}) => {
    const [apiNameInvalid, setApiNameInvalid] = useState<boolean>(true);
    const [, setSelectValue] = useState<string>(field.fieldType);
    const handleChangeName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            onChange({ ...field, fieldName: e.target.value });
        });
    };
    const handleChangeApiName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            onChange({ ...field, apiName: e.target.value });
        });
    };

    const handleChangeParams = (editedParams: IParams) => {
        startTransition(() => {
            onChange({ ...field, params: { ...editedParams } });
        });
    };

    const handleChangeType = (
        e: SelectChangeEvent<FieldType>
    ) => {
        setSelectValue(e.target.value);
        if (!allowedToFilter.includes(e.target.value)) {
            onChange({ ...field, fieldType: e.target.value as FieldType, filter: false });
        } else {
            onChange({ ...field, fieldType: e.target.value as FieldType });
        }
    };

    const maxOrder = structure
        ? structure.fields.length + structure.relations.length
        : 1;

    useEffect(() => {
        if (!field.apiName || !field.apiName.match('^[a-z]+$')) {
            setApiNameInvalid(true);
        } else {
            setApiNameInvalid(false);
        }
    }, [field.apiName]);

    return (
        <ElementBox>
            <Indicator />

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <MarginBox>
                        <TextField
                            variant="outlined"
                            name="fieldName"
                            label="Nombre"
                            fullWidth
                            defaultValue={field.fieldName}
                            onChange={(e) => { handleChangeName(e); }}
                        />
                    </MarginBox>
                    <MarginBox>
                        <TextField
                            variant="outlined"
                            name="apiName"
                            label="Nombre en API"
                            fullWidth
                            error={apiNameInvalid}
                            defaultValue={field.apiName}
                            onChange={(e) => { handleChangeApiName(e); }}
                        />
                    </MarginBox>
                    <MarginBox>
                        <FormControl fullWidth>
                            <InputLabel id="selector"> Tipo </InputLabel>
                            <Select
                                labelId="selector"
                                value={field.fieldType}
                                label="Tipo"
                                onChange={(
                                    e: SelectChangeEvent<FieldType>
                                ) => { handleChangeType(e); }}
                            >
                                {selectOptions.map((option, index: number) => (
                                    <MenuItem
                                        key={`${option.value}-${index.toString()}`}
                                        value={option.value}
                                    >
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MarginBox>
                    {field.fieldType === 'selector' && (
                        <MarginBox>
                            <Selector
                                field={field}
                                onChange={handleChangeParams}
                            />
                        </MarginBox>
                    )}
                    {field.fieldType === 'formula' && (
                        <MarginBox>
                            <Formula
                                field={field}
                                onChange={handleChangeParams}
                            />
                        </MarginBox>

                    )}
                    <MarginBox>
                        <FormControl fullWidth>
                            <InputLabel id="selector-order"> Orden </InputLabel>
                            <Select
                                labelId="selector-order"
                                value={field.order}
                                label="Tipo"
                                onChange={(e: SelectChangeEvent<number>) => {
                                    onChange({
                                        ...field,
                                        order: parseInt(e.target.value.toString(), 10)
                                    });
                                }}
                            >
                                {[...Array(maxOrder)].map((_, i: number) => (
                                    <MenuItem
                                        key={`option ${(i + 1)}`}
                                        value={i}
                                    >
                                        {(i + 1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MarginBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormControlLabel
                            control={(
                                <CheckBox
                                    checked={field.shown}
                                    onChange={() => {
                                        onChange({
                                            ...field,
                                            shown: !field.shown
                                        });
                                    }}
                                />
                            )}
                            label="Mostrar"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={(
                                <CheckBox
                                    checked={field.required}
                                    onChange={() => {
                                        onChange({
                                            ...field,
                                            required: !field.required
                                        });
                                    }}
                                />
                            )}
                            label="Obligatorio"
                        />
                    </FormGroup>
                    <FormGroup>
                        {allowedToFilter.includes(field.fieldType) && (
                            <FormControlLabel
                                control={(
                                    <CheckBox
                                        checked={field.filter}
                                        onChange={() => {
                                            onChange({
                                                ...field,
                                                filter: !field.filter
                                            });
                                        }}
                                    />
                                )}
                                label="Habilitar filtro"
                            />
                        )}
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        onClick={onRemove}
                    >
                        Eliminar campo
                    </Button>
                </Grid>
            </Grid>

        </ElementBox>
    );
};

export default Field;
