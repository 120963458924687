import axios from 'axios';
import { toast } from 'react-toastify';
import options from './toastOptions';

const url = (process.env.REACT_APP_BASE_URI_API) ? process.env.REACT_APP_BASE_URI_API : 'http://localhost:4000';
const axiosInstance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
        Pragma: 'no-cache',
        Expires: '0'
    }
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            window.location.href = '/auth/login';
        } else if (error.response.status === 401) {
            window.location.href = '/platforms';
        } else if (error.response.data && error.response.data.error) {
            toast.error(error.response.data.error, options);
        } else {
            toast.error('Oops, algo ha fallado', options);
        }

        throw error.response;
    }
);

export default axiosInstance;
