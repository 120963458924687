import React, {
    useState,
    useEffect,
    startTransition,
    useContext,
    useMemo
} from 'react';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Modal, { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import useAxios from '../../../../hooks/useAxios';
import { UserContext } from '../../../../App';

import { IUser, IRole } from '../../../../types/user';
import { StyledContainer } from './styles';

export interface IProps extends Omit<ModalProps, 'onSubmit'> {
    onSubmit: () => void;
}

const NewForm: React.FC<IProps> = ({ onSubmit, ...rest }) => {
    const [roles, setRoles] = useState<Array<IRole>>();
    const [role, setRole] = useState<string>();
    const [rootFolderSelector, setRootFolderSelector] = useState<string>('');
    const [newRootFolder, setNewRootFolder] = useState<boolean>(true);
    const [newFolderInput, setNewFolderInput] = useState<string>('');
    const [user, setUser] = useState<IUser>({
        username: '',
        email: '',
        hasAccessTo: []
    });

    const { platform } = useContext(UserContext);

    const handleChangeUsername = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setUser((prev: IUser) => ({ ...prev, username: e.target.value }));
        });
    };

    const handleChangeEmail = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setUser((prev: IUser) => ({ ...prev, email: e.target.value }));
        });
    };
    const handleChangeRole = (
        e: SelectChangeEvent<string>
    ) => {
        setRole(e.target.value);
    };

    const { axiosFetch } = useAxiosFunction();
    const { response: foldersResponse, axiosFetch: foldersFetch } = useAxiosFunction();

    const handleSubmit = async () => {
        if (!platform) { return; }
        try {
            await axiosFetch({
                url: `/api/platforms/${platform}/users`,
                method: 'post',
                data: {
                    username: user.username,
                    email: user.email,
                    roleId: role,
                    rootFolder: newRootFolder ? newFolderInput : rootFolderSelector
                }
            });
            onSubmit?.();
        } catch (err) {
            let message = 'Error';
            if (err instanceof Error) {
                message = err.message;
            }
            console.warn(message);
        }
    };

    const { response: roleResponse } = useAxios({
        url: '/api/roles/',
        method: 'get'
    });

    const renderNewFolderInput = () => (
        <TextField
            variant="outlined"
            spellCheck
            name="rootFolder"
            label="Carpeta raíz"
            fullWidth
            value={newFolderInput}
            onChange={(e) => { setNewFolderInput(e.target.value); }}
        />
    );

    useEffect(() => {
        if (roleResponse && Array.isArray(roleResponse)) {
            setRoles(() => roleResponse);
            if (roleResponse.length > 0) {
                setRole(roleResponse[0]._id);
            }
        }
    }, [roleResponse]);

    useEffect(() => {
        if (platform) {
            foldersFetch({
                url: `/api/platforms/${platform}/folders`,
                method: 'get'
            });
        }
    }, [platform]);

    const rootFolders = useMemo(() => {
        if (foldersResponse && Array.isArray(foldersResponse)) {
            return foldersResponse;
        }

        return undefined;
    }, [foldersResponse]);

    return (
        <Modal
            onSubmit={handleSubmit}
            {...rest}
        >
            <StyledContainer>
                <TextField
                    variant="outlined"
                    name="username"
                    label="Username"
                    fullWidth
                    defaultValue={user.username}
                    onChange={(e) => { handleChangeUsername(e); }}
                />
                <TextField
                    variant="outlined"
                    spellCheck
                    name="email"
                    label="Email"
                    fullWidth
                    defaultValue={user.email}
                    onChange={(e) => { handleChangeEmail(e); }}
                />
                {roles && roles.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel id="selector"> Rol </InputLabel>
                        <Select
                            labelId="selector"
                            value={role}
                            label="Rol"
                            onChange={handleChangeRole}
                        >
                            {roles.map((option, index: number) => (
                                <MenuItem
                                    key={`${option._id}-${index.toString()}`}
                                    value={option._id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {rootFolders && rootFolders.length > 0 && (
                    <FormGroup>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={newRootFolder}
                                    onChange={() => { setNewRootFolder(!newRootFolder); }}
                                />
                            )}
                            label="Crear nueva"
                        />
                    </FormGroup>
                )}
                {!newRootFolder && rootFolders && (
                    <FormControl fullWidth>
                        <InputLabel id="selector-root"> Carpeta Raíz </InputLabel>
                        <Select
                            labelId="root-folder-selector"
                            value={rootFolderSelector}
                            label="Carpeta raiz"
                            onChange={(e) => {
                                setNewFolderInput('');
                                setRootFolderSelector(e.target.value as string);
                            }}
                        >
                            {rootFolders.map((option) => (
                                <MenuItem
                                    key={option.name || ''}
                                    value={option.name}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                )}
                {newRootFolder && renderNewFolderInput()}
                {!newRootFolder && rootFolders && !rootFolders.length && renderNewFolderInput()}
            </StyledContainer>
        </Modal>
    );
};

export default NewForm;
