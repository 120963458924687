import React, { useState, useEffect, useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { IRelation } from '../../../../../types/bbdd';
import useAxios from '../../../../../hooks/useAxios';

export interface IProps {
    relation: IRelation;
    relationValue: Array<string>;
    onChange: (newValue: Array<string>) => void;
}

const Selector: React.FC<IProps> = ({
    relation, relationValue, onChange
}) => {
    const [filterValue, setFilterValue] = useState<{ value: string, text: string } | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const excludeList: string = useMemo(() => relationValue
        .map((value) => (
            `exclude[]=${value}`
        )).join('&'), [JSON.stringify(relationValue)]);

    const {
        response, loading, error, refetch
    } = useAxios({
        url: `/api/structures/${relation.relatedWith}/entries/candidates?${excludeList}`,
        method: 'get'
    });

    const handleChangeRelatedSec = (newValue: { value: string, text: string } | null) => {
        if (!newValue) { return; }
        if (!relation.blockThisSide) {
            if (relation.cardinality === 'OneToOne' || relation.cardinality === 'OneToMany') {
                onChange([newValue.value]);
            } else {
                const relatedIds = relationValue;
                relatedIds.push(newValue.value);
                onChange(relatedIds);
            }
        }
        setFilterValue(null);
        setInputValue('');
    };

    useEffect(() => {
        refetch();
    }, [JSON.stringify(relationValue)]);

    return (
        <FormControl fullWidth>
            <Box>
                {!loading
                    && !error
                    && response.options
                    && (
                        <Autocomplete
                            value={filterValue}
                            onChange={(
                                _event: any, newValue: { value: string, text: string } | null
                            ) => {
                                handleChangeRelatedSec(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(_event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            id={`${relation._id}-simple-relation-filter`}
                            options={response.options}
                            getOptionLabel={(option) => option.text}
                            renderInput={(
                                params
                            ) => <TextField {...params} label="Opciones" />}
                        />
                    )}
            </Box>
        </FormControl>

    );
};

export default Selector;
