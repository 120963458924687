import React, { useState, createContext } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import Row from './Row';

import { IBBDD } from '../../../../types/bbdd';

import { StyledTable } from '../../../atoms/structure/table/styles';

export interface IProps {
    structures: Array<IBBDD>;
    refetch: () => void;
}

export const StructuresContext = createContext<Array<IBBDD>>([]);

const Table: React.FC<IProps> = ({ structures, refetch }) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <Box sx={{ p: '2rem' }}>
                <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>
                    <StyledTable cellSpacing={0} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell><strong>Nombre</strong></TableCell>
                                <TableCell><strong>Entradas</strong></TableCell>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Estado</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StructuresContext.Provider value={structures}>
                                {structures
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((structure:IBBDD) => (
                                        <Row
                                            key={structure._id}
                                            structure={structure}
                                            refetch={refetch}
                                        />
                                    ))}
                            </StructuresContext.Provider>
                        </TableBody>

                    </StyledTable>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={structures.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default Table;
