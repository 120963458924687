import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

export const ElementBox = styled(Box)<BoxProps>(() => ({
    margin: '2rem 0',
    display: 'flex',
    gap: '1rem'
}));

export const Indicator = styled('div')(({ theme }) => ({
    width: '1rem',
    borderRadius: '0.2rem 0.5rem 0.2rem 0.5rem ',
    backgroundColor: alpha(theme.palette.primary.main, 0.5)
}));

export const MarginBox = styled(Box)(() => ({
    marginBottom: '2rem',
    width: '100%'
}));
