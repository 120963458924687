import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import {
    StyledContentBox, StyledHeaderContainer, ButtonContainer, ChildrenContainer
} from './styles';

export interface IProps {
    title?: string;
    counter?: number;
    buttonText?: string;
    buttonDisabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    buttonDisplayed?: boolean;
}

const ContentHeader: React.FC<IProps> = ({
    title,
    counter = 0,
    buttonText,
    onClick,
    buttonDisabled = false,
    buttonDisplayed = true,
    children
}: IProps) => (
    <StyledContentBox>
        <StyledHeaderContainer>
            <Typography
                variant="h2"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                maxWidth="60%"
            >
                { title }
            </Typography>
            <Typography variant="h4" whiteSpace="nowrap">
                |
                {' '}
                {counter}
            </Typography>
            {buttonDisplayed && (
                <>
                    <ButtonContainer display={{ xs: 'none', md: 'block' }}>
                        <Button
                            onClick={() => { onClick?.(); }}
                            disabled={buttonDisabled}
                            variant="contained"
                        >
                            { buttonText }
                        </Button>
                    </ButtonContainer>
                    <ButtonContainer display={{ xs: 'block', md: 'none' }}>
                        <Button
                            onClick={() => { onClick?.(); }}
                            disabled={buttonDisabled}
                            variant="contained"
                        >
                            <Add />
                        </Button>
                    </ButtonContainer>
                </>
            )}

            {children && (
                <ChildrenContainer>
                    {children}
                </ChildrenContainer>
            )}
        </StyledHeaderContainer>
    </StyledContentBox>
);

export default ContentHeader;
