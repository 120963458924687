import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import useAxios from '../../hooks/useAxios';

import NewForm from '../../components/molecules/bbdd/form/New';

import { IBBDD } from '../../types/bbdd';
import ContentHeader from '../../components/atoms/structure/contentHeader';
import Table from '../../components/molecules/bbdd/table';

import * as S from './styles';

interface IProps {
    updateSidebar: () => void;
}

export const StructuresContext = React.createContext<Array<IBBDD> | []>([]);

const BBDD: React.FC<IProps> = ({ updateSidebar }: IProps) => {
    const [structures, setStructures] = useState<IBBDD[] | []>([]);
    const [newPopupOpen, setNewPopupOpen] = useState<boolean>(false);

    const {
        response, loading, error, refetch
    } = useAxios({
        url: '/api/base',
        method: 'get'
    });

    useEffect(() => {
        setStructures(() => response || []);
    }, [response]);

    useEffect(() => {
        updateSidebar();
        refetch();
    }, [structures.length]);

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <ContentHeader
                title="BBDD"
                counter={structures?.length}
                buttonText="Nueva BBDD"
                onClick={() => {
                    setNewPopupOpen(!newPopupOpen);
                }}
            />
            { loading && <h1> Loading...</h1> }
            { !loading && !error && (
                <Table
                    structures={structures}
                    refetch={() => { updateSidebar(); refetch(); }}
                />
            ) }

            <Box overflow="auto">
                <StructuresContext.Provider value={structures}>
                    { loading && <h1> Loading...</h1> }
                    { !loading && !error && (
                        <S.Container />
                    ) }

                    {newPopupOpen && (
                        <NewForm
                            onClose={() => { setNewPopupOpen(false); }}
                            onSubmit={() => { refetch(); setNewPopupOpen(false); }}
                            open={newPopupOpen}
                            title="Nueva BBDD"
                        />
                    )}

                </StructuresContext.Provider>
            </Box>
        </Box>
    );
};

export default BBDD;
