import styled from 'styled-components';
import MuiTextField from '@mui/material/TextField';
import MuiBox, { BoxProps } from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiStack from '@mui/material/Stack';
import MuiButton from '@mui/material/Button';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Panel = styled.div`
    border-radius: 1em;
    box-shadow: 5px 2px 8px #888888;
    overflow: hidden;

    width: 20%;
    heigth: 20%;
    align-items: center;
    justify-content: center;

`;

export const RightSplit = styled.div`
    
`;

export const FormItem = styled.div`
    margin-top: 1em;
    width: 100%;
`;

export const Logo = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

export const Box = styled(Paper)`
    && {
        width: 50%;
        height: 80%;
        min-height: 300px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        @media screen and (max-width: ${(props) => (props.theme.sizes.desktop)}) {
            height: 80%;
            width: 80%;
        }
    }
`;

export const Stack = styled(MuiStack) <{ $fullWidth?: boolean }>`
    && {
        width: ${(props) => (props.$fullWidth ? '100%' : '70%')};
        @media screen and (max-width: ${(props) => (props.theme.sizes.tablet)}) {
            width: 100%;
        }
    }
`;

export const Button = styled(MuiButton)`
    && {
        margin-top: 1rem;
    };
`;

export const TextField = styled(MuiTextField)`
    && {
        width: 100%;
    }

`;

export const Form = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

`;

export const NewPassword = styled(MuiTextField)`
    && {
        width: 100%;
    }

`;

export const StyledEmail = styled(MuiBox)<BoxProps>(() => ({
    gap: '1rem',
    marginTop: '1rem'
}));

export const StyledTitle = styled(MuiBox)<BoxProps>(() => ({
    gap: '1rem',
    marginTop: '0.5rem'
}));

export const StyledButton = styled(MuiButton)`
    && {
        margin-top: 1rem;
    }

`;

export const StyledForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

`;
