import { styled, alpha } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const StyledRelationContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    padding: '1rem',
    borderRadius: '1rem',
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
}));

export const StyledRelationHeading = styled(Typography)<TypographyProps>(() => ({
    width: '100%'
}));

export const StyledIndicatorContainer = styled(Box)<BoxProps>(() => ({
    width: '100%',
    flexWrap: 'wrap'
}));

export const StyledIndicator = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    minHeight: '3rem',
    alignItems: 'center',
    padding: '0.3rem 1.5rem',
    borderRadius: '100rem',
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
    '&>span': {
        color: 'white'
    }
}));
