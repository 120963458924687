import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { UserContext } from '../../../App';

import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useUploadFile from '../../../hooks/useUploadFile';

import { StyledUploadContainer } from './styles';

export interface IProps {
    onSubmit: () => void;
}

const Upload: React.FC<IProps> = ({ onSubmit }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>('');
    const [error, setError] = useState<string>('');

    const { platform } = useContext(UserContext);

    const { folderId } = useParams();
    const { uploadFunction } = useUploadFile();

    const handleClose = () => {
        setError('');
        setInputText('');
        setOpen(false);
    };

    const { axiosFetch: createFolderPost } = useAxiosFunction();

    const handleSubmit = async () => {
        if (inputText && platform) {
            const folderQuery = folderId
                ? `/api/platforms/${platform}/folders/${folderId}/folders`
                : `/api/platforms/${platform}/folders`;

            await createFolderPost({
                url: folderQuery,
                method: 'post',
                data: {
                    name: inputText
                }
            });

            onSubmit();
            handleClose();
        }
    };

    const handleUploadFiles = async (files: FileList | null) => {
        if (files && platform) {
            const folderQuery = folderId ? `?parentId=${folderId}` : '';
            const uploadPromises = Array.from(files)
                .map(async (file: File) => uploadFunction({
                    file,
                    fileType: 'doc',
                    url: `/api/platforms/${platform}/files${folderQuery}`,
                    parent: folderId
                }));

            await Promise.all(uploadPromises);

            onSubmit();
        }
    };

    return (
        <StyledUploadContainer>
            <IconButton
                component="label"
                size="large"
                onClick={() => { onSubmit(); }}
                sx={{ transform: 'scale(1.4)' }}
            >
                <input type="file" onChange={(e) => { handleUploadFiles(e.target.files); }} hidden />
                <UploadFileIcon />
            </IconButton>
            <IconButton
                component="label"
                size="large"
                onClick={() => { setOpen(!open); }}
                sx={{ transform: 'scale(1.4)' }}
            >
                <CreateNewFolderIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Nombre de carpeta</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folderName"
                        label="name"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => { setInputText(e.target.value); }}
                    />
                    {error && (
                        <p>
                            {error}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" onClick={handleSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </StyledUploadContainer>
    );
};

export default Upload;
