import React, { useEffect, useState, useContext } from 'react';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import Form from '.';
import { IProps as ModalProps } from '../../sideModal';
import { ISimpleEntry, IBBDD } from '../../../../types/bbdd';
import { StructureContext } from '../table';

export interface IProps extends ModalProps {
    id: string;
}

const EditForm: React.FC<IProps> = ({ onSubmit, id, ...rest }) => {
    const [entry, setEntry] = useState<ISimpleEntry>();
    const structure: IBBDD | undefined = useContext(StructureContext);
    const {
        response, loading, error, axiosFetch
    } = useAxiosFunction();

    const {
        axiosFetch: postAxiosFetch
    } = useAxiosFunction();

    const onSubmitNewAction = async () => {
        if (structure && entry && entry._id) {
            await postAxiosFetch({
                url: `/api/structures/${structure._id}/entries/${entry._id}`,
                method: 'put',
                data: { ...entry }
            });
            onSubmit?.();
        }
    };

    useEffect(() => {
        if (structure) {
            axiosFetch({
                url: `/api/structures/${structure._id}/entries/${id}?populate=false`,
                method: 'get'
            });
        }
    }, []);

    useEffect(() => {
        setEntry(response);
    }, [response]);

    return (
        <>
            {!loading && !error && entry && (
                <Form
                    onSubmit={onSubmitNewAction}
                    entry={entry}
                    {...rest}
                />

            )}

        </>
    );
};

export default EditForm;
