import { useState, useEffect } from 'react';

import axios from '../utils/axiosIntercepter';

export interface IParams {
    url: string;
    method: 'get' | 'post' | 'options' | 'put' | 'delete';
    data?: {[x: string]: any};
    headers?: {[x:string]: any};
}

const useAxiosFunction = () => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [controller, setController] = useState<AbortController>();

    const axiosFetch = async (configObject: IParams) => {
        const {
            url, method, data, headers
        } = configObject;
        setLoading(true);
        setError('');
        const ctrl = new AbortController();
        setController(() => ctrl);
        try {
            const result = await axios[method](url, {
                headers,
                signal: ctrl.signal,
                data
            });
            setResponse(result.data || 'Success');
        } catch (err) {
            setResponse(undefined);
            setError(() => err.data || 'A server error has ocurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => () => controller && controller.abort(), [controller]);

    return {
        response, error, loading, axiosFetch
    };
};

export default useAxiosFunction;
