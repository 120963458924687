import { styled } from '@mui/material';
import styles from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import MuiStack from '@mui/material/Stack';

export const StyledContainer = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginTop: '2rem'
}));

export const StyledPropertyContainer = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '2rem',
    marginBottom: '2rem'
}));

export const StyledPropertyBox = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    gap: '1rem',
    padding: '0.5rem',
    borderRadius: '0.5rem'
}));

export const StyledPropertyFieldBox = styled(Box)<BoxProps>(() => ({
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem'
}));

export const StyledPropertyTextField = styled(TextField)<TextFieldProps>(() => ({
    flex: '1 1 300px'
}));

export default StyledContainer;

export const StyledChangePassword = styled(Box)<BoxProps>(() => ({
    gap: '1rem',
    marginTop: '1rem'
}));

export const StyledConfirmationTitle = styled(Box)<BoxProps>(() => ({
    gap: '1rem',
    marginTop: '0.5rem'
}));

export const StyledForm = styles.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

`;

export const StyledButton = styled(MuiButton)`
    && {
        margin-top: 1rem;
    }

`;

export const StyledStack = styled(MuiStack)<{$fullWidth?: boolean}>`
    && {
        width: ${(props) => (props.$fullWidth ? '100%' : '70%')};
       
    }
`;
