import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { StyledIndicator } from './styles';

export interface IProps {
    options: Array<{ value: string, text: string }>;
    current: string;
    disabled?: boolean;
    onRemove?: () => void;
}

const Indicator: React.FC<IProps> = ({
    options, current, onRemove, disabled
}) => {
    const [currentName, setCurrentName] = useState<string>('');

    useEffect(() => {
        if (current && options.length > 0) {
            const name = options.find((value) => value.value === current);
            setCurrentName(name?.text || '');
        }
    }, []);

    return (
        <StyledIndicator>
            {currentName}
            {!disabled && (
                <IconButton
                    onClick={onRemove}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                >
                    <DeleteIcon />
                </IconButton>

            )}
        </StyledIndicator>
    );
};

export default Indicator;
