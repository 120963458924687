import React, { useState, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import ContentHeader from '../../../atoms/structure/contentHeader';
import NewForm from '../form/New';
import Row from './Row';

import { UserContext } from '../../../../App';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { StyledTable } from '../../../atoms/structure/table/styles';

export type userType = { username: string, _id: string, email: string };

const Table: React.FC = () => {
    const [users, setUsers] = useState<Array<userType>>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [open, setOpen] = useState<boolean>(false);

    const { platform } = useContext(UserContext);

    const {
        response, error, loading, axiosFetch
    } = useAxiosFunction();

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchUsers = async () => {
        if (!platform) { return; }
        await axiosFetch({
            method: 'get',
            url: `/api/platforms/${platform}/users`
        });
    };

    useEffect(() => {
        setUsers(() => (response || []));
    }, [response]);

    useEffect(() => {
        if (platform) {
            fetchUsers();
        }
    }, [platform]);

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <ContentHeader
                title="Usuarios"
                counter={users.length}
                buttonText="Nuevo usuario"
                onClick={() => { setOpen(true); }}
            />
            <Box sx={{ p: '2rem' }}>
                {!loading && !error && (
                    <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>
                        <StyledTable cellSpacing={0} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell><strong>Usuario</strong></TableCell>
                                    <TableCell><strong>Email</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user: userType) => (
                                    <Row
                                        key={user._id}
                                        user={user}
                                        refetch={fetchUsers}
                                    />
                                ))}
                            </TableBody>

                        </StyledTable>
                    </TableContainer>

                )}
                {users && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Box>
            {open && (
                <NewForm
                    open={open}
                    onClose={() => { setOpen(false); }}
                    onSubmit={() => { fetchUsers(); setOpen(false); }}
                    title="Nuevo usuario"
                />
            )}
        </Box>
    );
};

export default Table;
