import React, { useState, useEffect } from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigate
} from 'react-router-dom';
import lodash from 'lodash';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Box from '@mui/material/Box';

import axiosInstance from './utils/axiosIntercepter';
import './styles/base/base.scss';
import 'font-awesome/css/font-awesome.min.css';

import DesktopSidebar from './components/layout/sidebar/desktop';
import MobileSidebar from './components/layout/sidebar/mobile';
import Content from './components/layout/content';
import Topbar from './components/layout/topbar';

import { ISidebarItems } from './components/layout/sidebar/sidebar';
import Users from './pages/users/Users';
import BBDD from './pages/bbdd';
import Config from './pages/config';
import Data from './pages/data';
import Docs from './pages/docs';
import Attachements from './pages/bbddDocs';
import Roles from './pages/roles';
import Logs from './pages/logs';

interface IUserContext {
    _id?: string;
    role?: string;
    platform?: string;
    username?: string;
    rootFolder?: string | null;
}

export const UserContext = React.createContext<IUserContext>({
});

const App: React.FC<{}> = () => {
    const [isColapsed, setIsColapsed] = useState(false);
    const [sidebarItems, setSidebarItems] = useState<ISidebarItems | undefined>();
    const [credentials, setCredentials] = useState<IUserContext>({});
    const [visualizationLink, setVisualizationLink] = useState<string | undefined>();

    const navigator = useNavigate();

    const setCredentialsContext = (
        _id?: string,
        role?: string,
        platform?: string,
        username?: string,
        rootFolder?: string | null
    ) => {
        setCredentials(() => ({
            role, _id, platform, username, rootFolder
        }));
    };

    const colapseHandler = () => {
        setIsColapsed(!isColapsed);
    };

    const updateSidebar = async () => {
        const axiosPromise = await axiosInstance.get('/api/base/sidebarList');
        const { data } = axiosPromise;

        setSidebarItems(() => (lodash.cloneDeep(data)));
    };

    const initialCall = async () => {
        try {
            const axiosResponse = await axiosInstance.get('/base');
            const {
                _id, role, platform, username, rootFolder
            } = axiosResponse.data;
            setCredentialsContext(_id, role, platform, username, rootFolder);
        } catch (err) {
            setSidebarItems(() => (undefined));
        }
    };

    const getLink = async () => {
        const response = await axiosInstance.get(`/api/platforms/${credentials.platform}`);
        const { visualizationRoute } = response.data;
        if (visualizationRoute) {
            setVisualizationLink(visualizationRoute);
        } else {
            const bbddQuery = await axiosInstance.get('/api/base');
            if (bbddQuery.status === 200 && bbddQuery.data && Array.isArray(bbddQuery.data)) {
                const arrayData: Array<{ _id: string }> = bbddQuery.data;
                if (arrayData.length > 0 && arrayData[0]._id) {
                    const id = arrayData[0]._id;
                    navigator(id);
                }
            }
        }
    };

    useEffect(() => {
        initialCall();
        updateSidebar();
    }, []);

    useEffect(() => {
        if (credentials.platform) {
            getLink();
        }
    }, [credentials]);

    return (
        <UserContext.Provider value={credentials}>
            <Topbar
                colapseHandler={colapseHandler}
            />

            <Box sx={{ display: { md: 'none' } }}>
                <MobileSidebar
                    isOpen={isColapsed}
                    colapseHandler={colapseHandler}
                    items={sidebarItems}
                />
            </Box>
            <LocalizationProvider dateAdapter={AdapterLuxon}>

                <div className="App full-view">
                    {sidebarItems
                        && (
                            <>
                                <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                    <DesktopSidebar items={sidebarItems} />
                                </Box>
                            </>
                        )}

                    <Routes>
                        <Route
                            index
                            element={(
                                <iframe
                                    src={visualizationLink || ''}
                                    frameBorder="0"
                                    title="view"
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            )}
                        />
                        <Route
                            path="/BBDD"
                            element={(
                                <BBDD updateSidebar={updateSidebar} />
                            )}
                        />
                        <Route
                            path="/roles"
                            element={(
                                <Roles />
                            )}
                        />
                        <Route
                            path="/documentacion"
                            element={(
                                <Content title="Documentación">
                                    <Docs />
                                </Content>
                            )}
                        />
                        <Route
                            path="/documentacion/:folderId"
                            element={(
                                <Content title="Documentación">
                                    <Docs />
                                </Content>
                            )}
                        />
                        <Route
                            path="/documentacion/structure/:structureId"
                            element={(
                                <Content title="Documentación">
                                    <Attachements />
                                </Content>
                            )}
                        />
                        <Route
                            path="/config"
                            element={(
                                <Content title="Configuración">
                                    <Config sidebarUpdater={updateSidebar} />
                                </Content>
                            )}
                        />
                        <Route
                            path="/users"
                            element={(
                                <Users />
                            )}
                        />
                        <Route
                            path="/logs"
                            element={(
                                <Logs />
                            )}
                        />
                        <Route
                            path="/:id"
                            element={(
                                <Data key={useLocation().pathname} />
                            )}
                        />
                    </Routes>
                </div>
            </LocalizationProvider>
        </UserContext.Provider>
    );
};

export default App;
