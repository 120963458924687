// m-theme.ts
import { DefaultTheme } from 'styled-components';

const myTheme: DefaultTheme = {
    colors: {
        primary: '#1a1a1a',
        secondary: '#2b2b2b',
        terciary: '#969696',
        cuaternary: '#404040',
        divider: '#E0E0E0',
        buttons: {
            primary: '#0048D9',
            success: '#4cae4c',
            warning: '#0048D9',
            danger: '#d9534f',
            icon: '#969696'
        }
    },
    sizes: {
        desktop: '1200px',
        smallScreen: '1024px',
        tablet: '768px',
        phone: '480px'

    }
};

export default myTheme;
