import { styled, alpha } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export const SpecialFieldBox = styled(Box)<BoxProps>(({ theme }) => ({
    border: `2px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    padding: '1rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}));

export const StyledOptionRow = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 1
}));
