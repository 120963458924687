import React, {
    useEffect,
    useState,
    createContext,
    useMemo,
    useContext
} from 'react';

import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import ContentHeader from '../../../atoms/structure/contentHeader';
import Filters from './filters';

import NewForm from '../form/New';
import ItemTableRow from './row';

import {
    IBBDD, ISimpleEntry
} from '../../../../types/bbdd';

import { IPermissionsRights } from '../../../../types/user';

import { useURIContext } from '../../../../utils/URIContext';
import axiosInstance from '../../../../utils/axiosIntercepter';
import useAxios from '../../../../hooks/useAxios';

import { UserContext } from '../../../../App';

import {
    StyledBox, StyledTable, StyledSearchField
} from './styles';

const defaultRights = {
    canEdit: true,
    canRead: true,
    canRemove: true,
    canCreate: true
};
export const StructureContext = createContext<IBBDD | undefined>(undefined);
export const PermissionsContext = createContext<IPermissionsRights>(defaultRights);

export type headerType = {
    type: 'field' | 'relation',
    name: string,
    apiName: string,
    innerApiName?: string,
    format: string,
    relatedWith?: string
};

const Table: React.FC<{ structureId: string }> = () => {
    const [flatItems, setFlatItems] = useState<Array<ISimpleEntry>>([]);
    const [structure, setStructure] = useState<IBBDD | undefined>();
    const [headers, setHeaders] = useState<Array<headerType>>([]);
    const [formStructure, setFormStructure] = useState<Array<any>>([]);
    const [displayFieldIndex, setDisplayFieldIndex] = useState<number>();
    const [permissions, setPermissions] = useState<IPermissionsRights>(defaultRights);
    const [searchInput, setSearchInput] = useState<string>('');
    const [filterQuery, setFilterQuery] = useState<{ [x: number]: string }>({});

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const { role } = useContext(UserContext);

    const { baseURI } = useURIContext();

    const { response: permissionsReponse } = useAxios({
        url: `${baseURI}/permissionRights`,
        method: 'get'
    });

    const getHeaders = async () => {
        try {
            const axiosPromiseHeaders = await axiosInstance.get(baseURI.concat('/headers'));
            setHeaders(axiosPromiseHeaders.data);
            const axiosPromiseForm = await axiosInstance.get(baseURI.concat('/form'));
            setFormStructure(axiosPromiseForm.data);
        } catch (err) {
            console.error(err);
        }
    };

    const getStructure = async () => {
        try {
            const axiosPromise = await axiosInstance.get(baseURI);
            setStructure(axiosPromise.data);
        } catch (err) {
            console.error(err);
        }
    };

    const refreshItems = async () => {
        const filterQueryString: string = Object.values(filterQuery).reduce((
            prev: string, curr: string
        ) => {
            if (!curr) { return prev; }
            return `${prev}&filter=${curr}`;
        }, '');
        try {
            if (structure) {
                const axiosPromise = await axiosInstance
                    .get(
                        `/api/structures/${structure._id}/entries?evaluate=true${filterQueryString === '?' ? '' : filterQueryString}&renderUser=true`
                    );
                setFlatItems(() => axiosPromise.data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const printHeaders = () => (
        <tr key="headers">
            <TableCell align="center" key="Actions">
                <strong>{' '}</strong>
            </TableCell>
            {headers.map((header: { name: string, apiName: string }) => (
                <TableCell align="center" key={header.apiName}>
                    <strong>{header && header.name ? header.name : ''}</strong>
                </TableCell>
            ))}

            { role === 'admin' && (
                <TableCell align="center">
                    <strong> Usuario </strong>
                </TableCell>
            )}
        </tr>
    );

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFilterData = (filterValue: string, index: number | string): void => {
        setFilterQuery((prev: { [x: number]: string }) => (
            { ...prev, [index]: filterValue }
        ));
    };

    const isPresentFilter = (
        value: any, filters: Array<string>, ignoreIndex: Array<number>
    ): { match: boolean, hitIndex: number } => {
        let hit: number = -1;
        const res = filters
            .some((filter: string, index: number) => {
                if (ignoreIndex.includes(index) || hit >= 0) { return false; }
                if (value === null || value === undefined || typeof value === 'object') { return false; }
                const val: string = value.toString().toLowerCase().trim();
                const fil: string = filter.toLowerCase().trim();
                if (!fil) { return true; }
                const match = val.match(fil);
                if (match) { hit = index; }
                return match;
            });
        return { match: res, hitIndex: hit };
    };

    const displayItems: Array<ISimpleEntry> = useMemo(() => {
        if (!structure || !flatItems || !Array.isArray(flatItems) || !flatItems.length) {
            return [];
        }
        if (!headers) { return []; }
        if (!searchInput) { return flatItems; }
        const lowerInput: string = searchInput.toLowerCase();
        const inputSegments: Array<string> = lowerInput.split(';').filter((v) => v);
        const shown: Array<ISimpleEntry> = flatItems
            .filter((item: ISimpleEntry) => {
                const matches: { m: number, ignoreIndex: Array<number> } = headers.reduce((
                    prev: any, header: headerType
                ) => {
                    let isMatch: { match: boolean, hitIndex: number } = {
                        match: false,
                        hitIndex: -1
                    };
                    if (header.type === 'field') {
                        if (header.format === 'boolean') {
                            const bool: string = item[header.apiName] ? item[header.apiName].toString() : 'false';
                            isMatch = isPresentFilter(bool, inputSegments, prev.ignoreIndex);
                            return isMatch.match
                                ? {
                                    m: prev.m + 1,
                                    ignoreIndex: [...prev.ignoreIndex, isMatch.hitIndex]
                                }
                                : prev;
                        }
                        if (typeof item[header.apiName] === 'undefined') { return prev; }

                        if (header.format === 'date' || header.format === 'datetime') {
                            const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' } as const;
                            const dateString = new Date(item[header.apiName] as string)
                                .toLocaleString('es-ES', dateOptions);
                            isMatch = isPresentFilter(dateString, inputSegments, prev.ignoreIndex);
                        } else if (header.format === 'time') {
                            const timeString = new Date(item[header.apiName] as string)
                                .toLocaleTimeString('es-ES');
                            isMatch = isPresentFilter(timeString, inputSegments, prev.ignoreIndex);
                        } else if (header.format === 'file') {
                            const val = item[header.apiName];
                            if (typeof val === 'object' && 'name' in val) {
                                isMatch = isPresentFilter(
                                    val.name, inputSegments, prev.ignoreIndex
                                );
                            }
                        } else {
                            isMatch = isPresentFilter(
                                item[header.apiName], inputSegments, prev.ignoreIndex
                            );
                        }
                    }
                    if (header.type === 'relation') {
                        if (!item[header.apiName] || !header.innerApiName) { return prev; }
                        if (!Array.isArray(item[header.apiName])) { return prev; }
                        const innerItem = item[header.apiName];

                        if (!Array.isArray(innerItem)) { return prev; }
                        innerItem.forEach((inner: ISimpleEntry | string) => {
                            if (isMatch.match) { return; }
                            if (typeof inner === 'string') { return; }
                            if (!inner[header.innerApiName!]) { return; }
                            isMatch = isPresentFilter(
                                inner[header.innerApiName!], inputSegments, prev.ignoreIndex
                            );
                        });
                    }

                    return isMatch.match
                        ? { m: prev.m + 1, ignoreIndex: [...prev.ignoreIndex, isMatch.hitIndex] }
                        : prev;
                }, { m: 0, ignoreIndex: [] });

                return matches.m === inputSegments.length;
            });

        return shown;
    }, [flatItems, searchInput, structure]);

    const downloadToCsv = () => {
        if (!headers || !displayItems || displayItems.length === 0) { return; }
        if (!structure || !structure.name) { return; }
        const csvHeadersString: string = headers
            .map((header: headerType) => {
                if (!header.name || !header.apiName) { return ''; }
                return header.name;
            })
            .join(',')
            .concat('\n');

        const csvData: Array<Array<string>> = displayItems
            .map((item: ISimpleEntry) => {
                const row: Array<string> = headers.map((header) => {
                    if (!header.name || !header.apiName) { return ''; }
                    if (header.format === 'boolean') { return item[header.apiName] ? item[header.apiName].toString() : 'FALSE'; }
                    if (typeof item[header.apiName] === 'undefined') { return ''; }
                    if (header.format === 'file') {
                        const val = item[header.apiName];
                        if (typeof val === 'object' && 'name' in val) {
                            return val.name;
                        }
                        return '';
                    }
                    if (header.type === 'relation') {
                        if (!header.innerApiName) { return ''; }
                        if (!Array.isArray(item[header.apiName])) { return ''; }
                        const inner: string = (item[header.apiName] as Array<ISimpleEntry | string>)
                            .map((elem: ISimpleEntry | string) => {
                                if (typeof elem === 'string') { return elem; }
                                if (typeof elem[header.innerApiName!] === 'undefined') { return ''; }
                                if (elem[header.innerApiName!].toString() === '[object Object]') { return ''; }
                                return elem[header.innerApiName!].toString();
                            })
                            .join(' | ');
                        return inner;
                    }
                    return item[header.apiName].toString();
                });

                return row;
            });

        const csvDataString: string = csvData
            .map((row: Array<string>) => row.join(','))
            .join('\n');

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,';
        hiddenElement.href += encodeURI(`${csvHeadersString}${csvDataString}`);
        hiddenElement.target = '_blank';

        hiddenElement.download = `Export_${structure.name}.csv`;
        hiddenElement.click();
    };

    useEffect(() => {
        if (structure) {
            refreshItems();
        }
    }, [structure]);

    useEffect(() => {
        getHeaders();
        getStructure();
    }, []);

    useEffect(() => {
        if (permissionsReponse) {
            setPermissions(() => permissionsReponse);
        }
    }, [permissionsReponse]);

    useEffect(() => {
        if (formStructure) {
            let displayIndex = formStructure.findIndex((
                value
            ) => (value.body.isDisplayField === true));
            if (displayIndex < 0) { displayIndex = 2; }
            setDisplayFieldIndex(() => displayIndex + 2);
        }
    }, [formStructure]);

    return (
        <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
            <ContentHeader
                title={structure?.name}
                counter={flatItems?.length || 0}
                buttonText="Nueva entrada"
                buttonDisabled={structure?.isLocked || !permissions.canCreate}
                onClick={() => { setPopupOpen(true); }}
            >
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <StyledSearchField
                        fullWidth
                        id="search"
                        value={searchInput}
                        variant="outlined"
                        onChange={(e) => { setSearchInput(e.target.value); }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" onClick={downloadToCsv}>
                            Exportar
                        </Button>
                    </Box>
                </Box>
            </ContentHeader>
            {structure && (
                <Box sx={{
                    display: 'flex',
                    m: 2,
                    gap: 2,
                    flexWrap: 'wrap',
                    alignItems: 'center'
                }}
                >
                    <Filters
                        onChange={handleChangeFilterData}
                        structure={structure}
                        refresh={refreshItems}
                    />
                </Box>
            )}

            <StyledBox flexGrow={1}>
                <StructureContext.Provider value={structure}>
                    <PermissionsContext.Provider value={permissions}>
                        <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>

                            <StyledTable displayIndex={displayFieldIndex} cellSpacing={0} aria-label="simple table">
                                <TableHead>
                                    {printHeaders()}
                                </TableHead>
                                <TableBody>
                                    {structure && displayItems && displayItems
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((
                                            // entry: IEntry
                                            entry: ISimpleEntry
                                        ) => (
                                            <ItemTableRow
                                                headers={headers}
                                                entry={entry}
                                                key={`Row-${entry._id}`}
                                                updater={refreshItems}
                                            />
                                        ))}
                                </TableBody>

                            </StyledTable>
                            {popupOpen && (
                                <NewForm
                                    open={popupOpen}
                                    title="Nueva entrada"
                                    onClose={() => { setPopupOpen(false); }}
                                    onSubmit={refreshItems}
                                />
                            )}
                        </TableContainer>
                    </PermissionsContext.Provider>
                </StructureContext.Provider>
                {displayItems && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={displayItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </StyledBox>

        </Box>
    );
};

export default Table;
