import styled from 'styled-components';

export const Card = styled.div`
    border-radius: 1em;
    padding: 1em;
    box-shadow: 0.3em 1em 1em lightgray;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 25rem;
    margin: 10px;
    background-color: white;
    
`;
//

export const Logo = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 40%;
    height: 7%;
    object-fit: contain;
    display: flex;
    align-content: justify;
    margin: 1px 142px 0px 140px;
`;

export const Top = styled.div`
height: 3rem;
display: inline-flex;
flex-direction: row;
position: fixed;
margin: 10px 10xp;
`;

export const CardSubtitle = styled.h2`
height: 3rem;
margin-top: 3rem;
`;

export const CardHeader = styled.h1`
height: 4rem;
font-size: 18px;
`;

export const CardContent = styled.div`
height: 15rem;
position: relative;
display: flex;
flex-direction: column;
overflow-y: auto;

`;

export const CardActions = styled.div`
height: 10rem;
margin-top: 15px;
`;
