import { styled, alpha } from '@mui/material';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import Box, { BoxProps } from '@mui/material/Box';

interface ICustomRowProps extends TableRowProps {
    collapse?: boolean;
}

export const StyledInfoContentRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== 'collapse'
})<ICustomRowProps>(({ theme, collapse }) => ({
    '& td:first-of-type': {
        border: collapse ? `1px solid ${alpha(theme.palette.secondary.main, 0.3)}` : 'none',
        borderRadius: '2rem'
    }
}));

export const StyledSegmentContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem',
    marginBottom: '2rem'
}));

export const StyledFieldIndicatorBox = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    borderRadius: '1rem',
    padding: '1rem 2rem',
    color: theme.palette.secondary.main
}));

export const StyledTypeIndicator = styled('span')(() => ({
    color: 'black',
    marginLeft: '2rem'
}));

export const StyledRelationIndicatorBox = styled(Box)<BoxProps>(({ theme }) => ({
    border: `3px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    borderRadius: '1rem',
    padding: '1rem 2rem'
}));

export const StyledRelationIndicator = styled('span')(({ theme }) => ({
    color: alpha(theme.palette.primary.main, 0.7),
    marginRight: '2rem'
}));
