import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
    position: 'absolute',
    right: '0%',
    backgroundColor: 'white',
    boxShadow: '24px',
    height: '90%',
    borderRadius: '0 0 0 2rem',
    overflowY: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: '0'
    },
    [theme.breakpoints.up('md')]: {
        width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
        width: '50%'
    }
}));

export const StyledHeader = styled(Box)<BoxProps>(({ theme }) => ({
    height: '8rem',
    color: 'white',
    width: '90%',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    padding: '0 4rem',
    marginRight: '2rem',
    borderRadius: '0 0 4rem 0',
    overflow: 'hidden'
}));

export const StyledContentContainer = styled(Box)<BoxProps>(() => ({
    flex: 1,
    width: '100%',
    overflow: 'auto',
    padding: '0 2rem'
}));

export const StyledActionContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.5rem 3rem'
}));

export const StyledButtonContainer = styled(Box)<BoxProps>(({ theme }) => ({
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flex: 1
    }
}));

export const AlertBox = styled(Box)<BoxProps>(() => ({
    flex: 1,
    minWidth: 300
}));
