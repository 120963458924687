import React, { useState, useEffect, startTransition } from 'react';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Modal, { IProps as ModalProps } from '../../sideModal';
import Field from './segments/Field';
import Relation from './segments/Relation';

import {
    IBaseBBDD, IBBDD, IField, IRelation
} from '../../../../types/bbdd';

import { FormSectorBox } from './styles';

export interface IProps extends Omit<ModalProps, 'onSubmit'>{
    structure: IBaseBBDD | IBBDD;
    onSubmit: (structure: IBaseBBDD | IBBDD) => void;
}

const Form: React.FC<IProps> = ({
    structure, open, onSubmit, ...rest
}) => {
    const [str, setStr] = useState<IBaseBBDD | IBBDD>(structure);
    const [isInvalid, setIsInvalid] = useState<boolean>(true);
    const [currKeyFieldIndex, setCurrKeyFieldIndex] = useState<number>(-1);
    const [currDisplayFieldIndex, setCurrDisplayFieldIndex] = useState<number>(-1);
    const [validationError, setValidationError] = useState<string>('');
    const [apiNameInvalid, setApiNameInvalid] = useState<boolean>(true);

    const handleChangeTitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setStr((prev: IBaseBBDD | IBBDD) => ({ ...prev, name: e.target.value }));
        });
    };

    const handleChangeApiName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setStr((prev: IBaseBBDD | IBBDD) => ({ ...prev, apiName: e.target.value }));
        });
    };
    const handleChangeOrder = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setStr((prev: IBaseBBDD | IBBDD) => ({ ...prev, order: parseFloat(e.target.value) }));
        });
    };
    const handleAddField = () => {
        setStr((prev: IBaseBBDD | IBBDD) => ({
            ...prev,
            fields: [
                ...str.fields, {
                    fieldName: 'Campo nuevo',
                    fieldType: 'string',
                    required: false,
                    isKeyField: false,
                    shown: true,
                    order: str.fields.length + str.relations.length + 1,
                    new: true
                }
            ]
        }));
    };

    const handleAddRelation = () => {
        setStr((prev: IBaseBBDD | IBBDD) => ({
            ...prev,
            relations: [
                ...str.relations, {
                    relatedWith: '',
                    cardinality: 'OneToOne',
                    newRelation: true,
                    blockThisSide: false,
                    required: false,
                    order: str.fields.length + str.relations.length + 1,
                    new: true
                }
            ]
        }));
    };

    const handleChangeField = (modField: IField, index: number) => {
        setStr((prev) => {
            const { fields } = str;
            fields[index] = { ...modField };
            return (
                { ...prev, fields: [...fields] }
            );
        });
    };

    const handleChangeRelation = (modRelation: IRelation, index: number) => {
        setStr((prev) => {
            const { relations } = str;
            relations[index] = { ...modRelation };
            return (
                { ...prev, relations: [...relations] }
            );
        });
    };

    const handleRemoveField = (index: number) => {
        const { fields } = str;
        fields.splice(index, 1);
        setStr((prev) => ({ ...prev, fields: [...fields] }));
    };

    const handleRemoveRelation = (index: number) => {
        const { relations } = str;
        relations.splice(index, 1);
        setStr((prev) => ({ ...prev, relations: [...relations] }));
    };

    const handleChangeKeyField = (event: SelectChangeEvent<number>) => {
        const { fields } = str;
        const newIndex = parseInt(event.target.value.toString(), 10);
        fields[currKeyFieldIndex].isKeyField = false;
        fields[newIndex].isKeyField = true;

        setStr((prev) => ({ ...prev, fields: [...fields] }));
        setCurrKeyFieldIndex(newIndex);
    };

    const handleChangeDisplayField = (event: SelectChangeEvent<number>) => {
        const { fields } = str;
        const newIndex = parseInt(event.target.value.toString(), 10);
        fields[currDisplayFieldIndex].isDisplayField = false;
        fields[newIndex].isDisplayField = true;

        setStr((prev) => ({ ...prev, fields: [...fields] }));
        setCurrDisplayFieldIndex(newIndex);
    };

    const checkIsStrValid = () => {
        if (!str.name) {
            setIsInvalid(true);
            setValidationError('El campo "Nombre" es obligatorio');
            return;
        }

        if (!str.apiName || !str.apiName.match('^[a-z]+$')) {
            setIsInvalid(true);
            setValidationError('Los  nombres en API deben ser no nulos y solo pueden contener letras minúsculas');
            return;
        }

        let hasKeyField = false;
        let hasDisplayField = false;
        let hasInvalidChars = false;
        let hasInvalidApiNames = false;

        const fieldNames = str.fields
            .map((field: IField) => {
                if (field.isKeyField) { hasKeyField = true; }
                if (field.isDisplayField) { hasDisplayField = true; }
                if (!field.fieldName.match('^[A-Za-záéíóúñ$€\\[\\] ]+$')) { hasInvalidChars = true; }
                return field.fieldName;
            })
            .filter((val: string) => val);
        const uniqueFields = [...new Set(fieldNames)];

        const apiNames = [...str.fields, ...str.relations]
            .map((
                elem: IField | IRelation
            ) => elem.apiName).filter((value: string | undefined) => value);

        const uniqueApiNames = [...new Set(apiNames)];
        uniqueApiNames.forEach((value) => {
            if (!value || !value.match('^[a-z]+$')) {
                hasInvalidApiNames = true;
            }
        });

        if (hasInvalidChars) {
            setIsInvalid(true);
            setValidationError('El nombre del campo solo pude contener letras y espacios');
            return;
        }

        if (hasInvalidApiNames) {
            setIsInvalid(true);
            setValidationError('Los  nombres en API deben ser no nulos y solo pueden contener letras minúsculas');
            return;
        }

        if (!hasDisplayField && hasKeyField) {
            setIsInvalid(true);
            setValidationError('Es obligatorio indicar un campo clave y un campo a mostrar');
            return;
        }

        if (uniqueFields.length !== str.fields.length) {
            setIsInvalid(true);
            setValidationError('Todos los campos tienen que tener un nombre único');
            return;
        }

        if (uniqueApiNames.length !== (str.fields.length + str.relations.length)) {
            setIsInvalid(true);
            setValidationError('Todos los campos y relaciones tienen que tener un nombre de API único');
            return;
        }

        const relatedWithNames = str.relations
            .map((relation: IRelation) => (relation.relatedWith))
            .filter((val: string) => val);

        if (relatedWithNames.length !== str.relations.length) {
            setIsInvalid(true);
            setValidationError('Todos las relaciones tiene que tener una BBDD relacionada');
            return;
        }

        setIsInvalid(false);
        setValidationError('');
    };

    const handleSubmit = () => {
        if (!isInvalid) {
            onSubmit(str);
        }
    };

    const processCurrentKeyFieldIndex = () => {
        if (!str || str.fields.length === 0) {
            setCurrKeyFieldIndex(-1);
        }
        const currentIndex = str.fields.findIndex((value: IField) => (value.isKeyField));
        if (currentIndex < 0) {
            const { fields } = str;
            fields[0].isKeyField = true;
            setStr((prev) => ({ ...prev, fields: [...fields] }));
            setCurrKeyFieldIndex(0);
        } else {
            setCurrKeyFieldIndex(currentIndex);
        }
    };

    const processCurrentDisplayFieldIndex = () => {
        if (!str || str.fields.length === 0) {
            setCurrDisplayFieldIndex(-1);
        }
        const currentIndex = str.fields.findIndex((value: IField) => (value.isDisplayField));
        if (currentIndex < 0) {
            const { fields } = str;
            fields[0].isDisplayField = true;
            setStr((prev) => ({ ...prev, fields: [...fields] }));
            setCurrDisplayFieldIndex(0);
        } else {
            setCurrDisplayFieldIndex(currentIndex);
        }
    };

    useEffect(() => {
        startTransition(() => {
            checkIsStrValid();
            processCurrentKeyFieldIndex();
            processCurrentDisplayFieldIndex();
        });
    }, [JSON.stringify(str)]);

    useEffect(() => {
        if (!str.apiName || !str.apiName.match('^[a-z]+$')) {
            setApiNameInvalid(true);
        } else {
            setApiNameInvalid(false);
        }
    }, [str.apiName]);

    return (
        <Modal
            {...rest}
            open={open}
            alertText={validationError}
            onSubmit={handleSubmit}
            isDisabledSubmit={isInvalid}
        >
            <FormSectorBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" mb={2}>
                            Datos generales
                        </Typography>
                        <TextField
                            variant="outlined"
                            spellCheck
                            lang="es"
                            name="name"
                            label="Nombre"
                            fullWidth
                            defaultValue={str.name}
                            onChange={(e) => { handleChangeTitle(e); }}
                        />
                        <TextField
                            variant="outlined"
                            name="apiName"
                            label="Nombre en API"
                            fullWidth
                            error={apiNameInvalid}
                            defaultValue={str.apiName}
                            sx={{ mt: 2 }}
                            onChange={(e) => { handleChangeApiName(e); }}
                        />
                        <TextField
                            variant="outlined"
                            name="order"
                            label="Orden"
                            fullWidth
                            type="number"
                            defaultValue={str.order}
                            sx={{ mt: 2 }}
                            onChange={(e) => { handleChangeOrder(e); }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Typography variant="h5">
                            Estados
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={str.isLocked}
                                        onChange={() => (setStr(
                                            (prev) => ({ ...prev, isLocked: !str.isLocked })
                                        ))}
                                    />
                                )}
                                label="Bloqueada"
                            />
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={str.isHidden}
                                        onChange={() => (setStr(
                                            (prev) => ({ ...prev, isHidden: !str.isHidden })
                                        ))}
                                    />
                                )}
                                label="Oculta"
                            />
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={str.isMaster}
                                        onChange={() => (setStr(
                                            (prev) => ({ ...prev, isMaster: !str.isMaster })
                                        ))}
                                    />
                                )}
                                label="Tabla maestra"
                            />

                        </FormGroup>
                    </Grid>

                    { currKeyFieldIndex >= 0 && (
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="key-field-selector"> Campo clave</InputLabel>
                                <Select
                                    labelId="key-field-selector"
                                    value={currKeyFieldIndex}
                                    label="Tipo"
                                    onChange={(
                                        e: SelectChangeEvent<number>
                                    ) => { handleChangeKeyField(e); }}
                                >
                                    { str.fields.map((option, index: number) => (
                                        <MenuItem
                                            key={`${option.fieldName}-${index.toString()}`}
                                            value={index}
                                        >
                                            {option.fieldName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) }
                    { currDisplayFieldIndex >= 0 && (
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="display-field-selector"> Campo mostrado </InputLabel>
                                <Select
                                    labelId="display-field-selector"
                                    value={currDisplayFieldIndex}
                                    label="Tipo"
                                    onChange={(
                                        e: SelectChangeEvent<number>
                                    ) => { handleChangeDisplayField(e); }}
                                >
                                    { str.fields.map((option, index: number) => (
                                        <MenuItem
                                            key={`${option.fieldName}-${index.toString()}`}
                                            value={index}
                                        >
                                            {option.fieldName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) }
                </Grid>
            </FormSectorBox>
            <FormSectorBox>
                <Typography variant="h5" mb={2}>
                    Campos
                </Typography>

                <Button variant="contained" onClick={handleAddField}>
                    Nuevo campo
                </Button>

                { str.fields.map((field: IField, index: number) => (
                    <Field
                        field={field}
                        key={`${str.name}-field-${index.toString()}`}
                        structure={str}
                        onChange={
                            (modField: IField) => { handleChangeField(modField, index); }
                        }
                        onRemove={() => { handleRemoveField(index); }}
                    />
                ))}
            </FormSectorBox>

            <FormSectorBox>
                <Typography variant="h5" mb={2}>
                    Relaciones
                </Typography>

                <Button variant="contained" onClick={handleAddRelation}>
                    Nueva relacion
                </Button>

                {str.relations?.map((relation: IRelation, index: number) => (
                    <Relation
                        relation={relation}
                        key={`${str.name}-relation-${index.toString()}`}
                        structure={str}
                        onChange={
                            (modRelation: IRelation) => {
                                handleChangeRelation(modRelation, index);
                            }
                        }
                        onRemove={() => { handleRemoveRelation(index); }}
                    />
                ))}
            </FormSectorBox>

        </Modal>

    );
};

export default Form;
