import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import myTheme from './themes/theme';
import MuiTheme from './themes/MuiTheme';
import App from './App';
import Cookie from './components/atoms/structure/cookies';

import Login from './pages/auth/login';
import Password from './pages/auth/password';
import Platforms from './pages/platforms';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ResetPassword from './pages/auth/resetPassword';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={myTheme}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/platforms" element={<Platforms />} />
                            <Route path="/auth/login" element={<Login />} />
                            <Route path="/auth/resetPassword/:token" element={<ResetPassword />} />
                            <Route path="/auth/validate/:token" element={<Password />} />
                            <Route path="/*" element={<App />} />
                        </Routes>
                    </BrowserRouter>
                    <Cookie />
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ThemeProvider>
        </MuiThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();

reportWebVitals();
