import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import Table, { TableProps } from '@mui/material/Table';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha } from '@mui/material';

export const StyledOpenTrigger = styled.div`
    padding: 1em 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
`;
export const DummyBox = MuiStyled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    height: 100
}));

export const StyledBox = MuiStyled(Box)<BoxProps>(() => ({
    width: '100%',
    padding: '2rem'
}));

interface ICustomTableProps extends TableProps {
    displayIndex?: number;
}

export const StyledTable = MuiStyled(Table, {
    shouldForwardProp: (prop) => prop !== 'displayIndex'
})<ICustomTableProps>((
    { theme, displayIndex }
) => ({
    borderCollapse: 'separate',
    borderSpacing: '0 15px',
    '& tbody:before': {
        content: "'.'",
        display: 'block',
        lineHeigth: 20,
        textIndent: '-99px'
    },
    '& th': {
        borderLeft: 'none',
        borderRight: 'none',
        margin: 'inherit 0',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    '& th:first-of-type': {
        borderRadius: '100rem 0 0 100rem',
        borderRight: 'none',
        width: 150
    },
    '& th:last-of-type': {
        borderRadius: '0 100rem 100rem 0'
    },
    '& td': {
        borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
        borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
        border: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
        borderLeft: 'none',
        borderRight: 'none',
        margin: 'inherit 0',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px'

    },

    '& td:first-of-type': {
        borderLeft: `1px solid ${alpha(theme.palette.secondary.main, 0.3)} `,
        borderRight: 'none',
        borderRadius: '100rem 0 0 100rem',
        width: 125
    },

    '& td:last-of-type': {
        borderRight: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
        borderRadius: '0 100rem 100rem 0'
    },
    [theme.breakpoints.down('lg')]: {
        '& td, & th': {
            maxWidth: '200px'
        }
    },
    [theme.breakpoints.down('md')]: {
        tableLayout: 'fixed',
        '& td, & th': {
            display: 'none'
        },

        [`& th:nth-of-type(1),& th:nth-of-type(${displayIndex || '2'})`]: {
            display: 'table-cell'
        },
        [`& th:nth-of-type(${displayIndex || '2'})`]: {
            borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
            borderRadius: '0 100rem 100rem 0'
        },
        [`& td:nth-of-type(1),& td:nth-of-type(${displayIndex || '2'})`]: {
            display: 'table-cell'
        },
        [`& td:nth-of-type(${displayIndex || '2'})`]: {
            borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
            borderRadius: '0 100rem 100rem 0'
        }
    }
}));
