import React, { useState, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ResetFlagContext } from '.';

interface IFilterProps {
    name: string,
    apiName: string,
    index: number,
    onChange: (filterValue: string, index: number | string) => void,
}
const BooleanFilter: React.FC<IFilterProps> = ({
    index, name, apiName, onChange
}) => {
    const [filterValue, setFilterValue] = useState<string>('all');
    const resetFlag: number = useContext(ResetFlagContext);

    const handleChange = (event: SelectChangeEvent) => {
        setFilterValue(event.target.value as string);
        if (event.target.value === 'true') {
            onChange(`${apiName}_true`, index);
        } else if (event.target.value === 'false') {
            onChange(`${apiName}_false`, index);
        } else {
            onChange('', index);
        }
    };

    useEffect(() => {
        if (resetFlag) {
            setFilterValue('all');
            onChange('', index);
        }
    }, [resetFlag]);

    return (
        <Box sx={{
            p: 2,
            border: '2px solid lightgray',
            borderRadius: '1rem',
            display: 'flex',
            gap: '1rem'
        }}
        >
            <FormControl>
                <InputLabel id={`boolean-filter-${apiName}-label`}>
                    {name}
                </InputLabel>
                <Select
                    labelId={`boolean-filter-${apiName}-label`}
                    id={`boolean-filter-${apiName}`}
                    value={filterValue}
                    label={name}
                    onChange={handleChange}
                >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="true">Cierto</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default BooleanFilter;
