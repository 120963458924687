import React from 'react';

import StorageIcon from '@mui/icons-material/Storage';

import {
    StyledTextBox, StyledFolderLink, StyledDocContainer, StyledActionBox, StyledDateBox,
    ResponsiveDocs
} from './styles';

export interface IProps {
    structureId: string;
    structureName: string;
}

const StructureFolder: React.FC<IProps> = ({
    structureId, structureName
}) => (
    <ResponsiveDocs>
        <StyledDocContainer>
            <StyledFolderLink to={`/documentacion/structure/${structureId}`}>
                <StyledTextBox>
                    <StorageIcon color="primary" />
                    <strong style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    >
                        {structureName}

                    </strong>
                </StyledTextBox>
            </StyledFolderLink>
            <StyledDateBox sx={{ display: { xs: 'none', md: 'inline-block' } }} />
            <StyledActionBox />
        </StyledDocContainer>
    </ResponsiveDocs>
);
export default StructureFolder;
