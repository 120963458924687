import { ToastOptions } from 'react-toastify';

export const options: ToastOptions = {
    position: 'bottom-center',
    autoClose: 5000,
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: 0
};

export default options;
