import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

import Button, { ButtonProps } from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledButtonContainer = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    widht: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'center'
}));

interface ICustomButtonProps extends ButtonProps {
    selected?: boolean;
}

export const StyledButton = styled(Button)<ICustomButtonProps>(({ selected, theme }) => ({
    height: '6em',
    width: '6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background 0.2s ease-in',
    borderRadius: '1rem',
    backgroundColor: selected ? alpha(theme.palette.primary.main, 0.5) : '',
    border: '2px solid #505050',
    '&:hover': {
        cursor: 'pointer',
        background: '#707070'
    },
    '&& .MuiTouchRipple-child': {
        backgroundColor: '#505050'
    }
}));
