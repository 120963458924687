import React, { useState, useMemo, createContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import NumberFilter from './NumberFilter';
import StringFilter from './StringFilter';
import RelationFilter from './RelationFilter';

import { IBBDD, IField, IRelation } from '../../../../../types/bbdd';

export interface IProps {
    structure: IBBDD;
    onChange: (filterValue: string, index: number | string) => void;
    refresh: () => void;
}

type filterType = { name: string, apiName: string, type: string };
type relationFilterType = { apiName: string, relatedWith: string };

export const ResetFlagContext = createContext<number>(0);

const Filters: React.FC<IProps> = ({ structure, onChange, refresh }) => {
    const [resetFlag, setResetFlag] = useState<number>(0);
    const filters: Array<filterType> | undefined = useMemo(() => {
        if (!structure) { return []; }
        const fil: Array<filterType> = structure.fields
            .reduce((
                prev: any, curr: IField
            ) => {
                if (!curr.apiName || !curr.fieldType || !curr.fieldName) { return prev; }
                if (!curr.filter) { return prev; }
                return [...prev, {
                    name: curr.fieldName,
                    apiName: curr.apiName,
                    type: curr.fieldType
                }];
            }, []);

        return fil;
    }, [structure]);

    const relationFilters: Array<relationFilterType> = useMemo(() => {
        if (!structure) { return []; }
        const fil: Array<relationFilterType> = structure.relations
            .reduce((
                prev: any, curr: IRelation
            ) => {
                if (!curr.apiName || !curr.relatedWith) { return prev; }
                if (!curr.filter) { return prev; }
                return [...prev, {
                    apiName: curr.apiName,
                    relatedWith: curr.relatedWith
                }];
            }, []);

        return fil;
    }, [structure]);

    return (
        <ResetFlagContext.Provider value={resetFlag}>
            <>
                {filters
                    ? filters.map((filter: filterType, index: number) => {
                        if (filter.type === 'boolean') {
                            return (
                                <BooleanFilter
                                    key={filter.apiName}
                                    name={filter.name}
                                    apiName={filter.apiName}
                                    index={index}
                                    onChange={onChange}
                                />
                            );
                        }
                        if (filter.type === 'string' || filter.type === 'selector') {
                            return (
                                <StringFilter
                                    key={filter.apiName}
                                    name={filter.name}
                                    apiName={filter.apiName}
                                    index={index}
                                    onChange={onChange}
                                />
                            );
                        }
                        if (filter.type === 'number') {
                            return (
                                <NumberFilter
                                    key={filter.apiName}
                                    name={filter.name}
                                    apiName={filter.apiName}
                                    index={index}
                                    onChange={onChange}
                                />
                            );
                        }
                        if (filter.type === 'date') {
                            return (
                                <DateFilter
                                    key={filter.apiName}
                                    name={filter.name}
                                    apiName={filter.apiName}
                                    index={index}
                                    onChange={onChange}
                                />
                            );
                        }
                        if (filter.type === 'datetime') { return (<></>); }
                        return (<></>);
                    })
                    : (<></>)}
                {relationFilters
                    ? relationFilters.map((filter: relationFilterType, index: number) => (
                        <RelationFilter
                            key={filter.apiName}
                            apiName={filter.apiName}
                            index={index}
                            relatedWith={filter.relatedWith}
                            onChange={onChange}
                        />
                    ))
                    : (<></>)}
                {(filters.length + relationFilters.length) > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Button variant="contained" onClick={refresh}>
                            Aplicar
                        </Button>
                        <Button variant="contained" onClick={() => { setResetFlag((prev) => prev + 1); }}>
                            Limpiar
                        </Button>
                    </Box>
                )}
            </>
        </ResetFlagContext.Provider>
    );
};

export default Filters;
