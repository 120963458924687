import React, { useContext } from 'react';

import { IBBDD, IRelation } from '../../../../types/bbdd';
import { StructuresContext } from '.';

import { StyledRelationIndicatorBox, StyledRelationIndicator } from './styles';

export interface IProps {
    relation: IRelation;
}

const RelationIndicator: React.FC<IProps> = ({ relation }) => {
    const structures = useContext(StructuresContext);

    const getRelatedStructureName = (): string => {
        const relatedWith = structures.find((
            value: IBBDD
        ) => (value._id === relation.relatedWith));

        if (!relatedWith) { return ''; }
        return relatedWith.name;
    };

    const getRelatedStructureDisplayName = (): string => {
        const relatedWith = structures.find((
            value: IBBDD
        ) => (value._id === relation.relatedWith));
        if (!relatedWith) { return ''; }

        const displayFieldName = relatedWith.fields.find((value) => value.isDisplayField);
        if (!displayFieldName) { return ''; }

        return ` / ${displayFieldName.fieldName}`;
    };

    return (
        <StyledRelationIndicatorBox>
            <StyledRelationIndicator>
                Relación:
            </StyledRelationIndicator>
            {getRelatedStructureName()}
            {getRelatedStructureDisplayName()}
        </StyledRelationIndicatorBox>
    );
};

export default RelationIndicator;
