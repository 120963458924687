import React, {
    useState,
    useContext,
    useMemo,
    useEffect
} from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { UserContext } from '../../../../App';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

type userOptionType = { username: string, _id: string };

export interface IProps {
    onChange: (_id: string) => void;
}

const CreatedBySelector: React.FC<IProps> = ({ onChange }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [filterValue, setFilterValue] = useState<userOptionType | null>(null);

    const { response, axiosFetch } = useAxiosFunction();
    const {
        role,
        platform,
        username,
        _id
    } = useContext(UserContext);

    const handleChangeCreateAs = (newValue: userOptionType | null) => {
        setFilterValue(() => newValue);
        setInputValue('');
        if (newValue) {
            onChange(newValue._id);
        }
    };

    const options: Array<userOptionType> = useMemo(() => {
        if (!_id || !username || role !== 'admin' || !response) { return []; }

        const returnValue = [{ username, _id }, ...response]
            .reduce((prev: Array<userOptionType>, curr: userOptionType) => {
                const presentIndex: number = prev
                    .findIndex((v: userOptionType) => v._id === curr._id);

                if (presentIndex === -1) {
                    return [...prev, curr];
                }

                return prev;
            }, []);

        return returnValue;
    }, [response, username, _id, role]);

    useEffect(() => {
        if (role === 'admin' && platform) {
            axiosFetch({
                url: `/api/platforms/${platform}/users`,
                method: 'get'
            });
        }
    }, [role, platform]);

    if (role !== 'admin') { return (<></>); }
    return (
        <Autocomplete
            value={filterValue}
            onChange={(
                _event: any, newValue: userOptionType | null
            ) => {
                handleChangeCreateAs(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="create-as-user-selector"
            options={options}
            getOptionLabel={(option) => option.username}
            renderInput={(
                params
            ) => <TextField {...params} label="Crear como" />}
        />
    );
};

export default CreatedBySelector;
