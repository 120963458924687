import React, {
    useState,
    useEffect,
    useContext,
    startTransition
} from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ResetFlagContext } from '.';

interface IFilterProps {
    name: string,
    apiName: string,
    index: number,
    onChange: (filterValue: string, index: number | string) => void,
}
const StringFilter: React.FC<IFilterProps> = ({
    index, name, apiName, onChange
}) => {
    const [filterValue, setFilterValue] = useState<string>('');
    const resetFlag: number = useContext(ResetFlagContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            if (!event.target.value) {
                onChange('', index);
            } else {
                const parsedString: string = event.target.value.replace(' ', '%20');
                onChange(`${apiName}_${parsedString}`, index);
            }
        });
        setFilterValue(event.target.value);
    };

    useEffect(() => {
        if (resetFlag) {
            setFilterValue('');
            onChange('', index);
        }
    }, [resetFlag]);

    return (
        <Box sx={{
            p: 2,
            border: '2px solid lightgray',
            borderRadius: '1rem',
            display: 'flex',
            gap: '1rem'
        }}
        >
            <TextField
                id={`string-filter-${apiName}`}
                label={name}
                value={filterValue}
                onChange={handleChange}
            />
        </Box>
    );
};

export default StringFilter;
