import React from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0.8rem'
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: () => (<ArrowDropDownIcon sx={{ color: '#7c7c7c' }} />)
            }
        },
        MuiCheckbox: {
            defaultProps: {
                icon: <CircleUnchecked />,
                checkedIcon: <CircleCheckedFilled />
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: '6rem',
                    ...(ownerState.variant === 'contained'
                        && (ownerState.color === 'primary') && {
                        color: '#fff'
                    })
                })
            }
        }

    },
    palette: {
        primary: {
            main: '#7C7C7C'
        },
        secondary: {
            main: '#505050'
        }
    },
    typography: {
        fontFamily: [
            'Comfortaa',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});
export default responsiveFontSizes(theme);
