import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import toastOptions from '../../../../utils/toastOptions';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import Form from '.';
import CreatedBySelector from './CreatedBySelector';
import { IProps as ModalProps } from '../../sideModal';
import { StructureContext } from '../table';

import { IBBDD } from '../../../../types/bbdd';

const NewForm: React.FC<ModalProps> = ({ onSubmit, ...rest }) => {
    const [entry] = useState<{ [x: string]: any }>({});
    const structure: IBBDD | undefined = useContext(StructureContext);

    const {
        response: postResponse,
        axiosFetch: postAxiosFetch
    } = useAxiosFunction();

    const onSubmitNewAction = async () => {
        if (structure) {
            await postAxiosFetch({
                url: `/api/structures/${structure._id}/entries`,
                method: 'post',
                data: { ...entry }
            });
            onSubmit?.();
        }
    };

    const onChangeCreatedBy = (_id: string) => {
        /* eslint-disable no-underscore-dangle */
        entry._createdBy = _id;
    };

    useEffect(() => {
        if (postResponse) {
            toast.success('Entrada creada correctamente', toastOptions);
        }
    }, [postResponse]);

    return (
        <>
            {entry && (
                <Form
                    onSubmit={onSubmitNewAction}
                    entry={entry}
                    {...rest}
                >
                    <CreatedBySelector onChange={onChangeCreatedBy} />
                </Form>

            )}

        </>
    );
};

export default NewForm;
