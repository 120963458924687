import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledContentBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    width: '100%'

}));
export const StyledHeaderContainer = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '90%',
    borderRadius: '0 0 3rem 0',
    color: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '1rem 2rem',
    marginRight: '10%'

}));

export const ButtonContainer = styled(Box)<BoxProps>(() => ({
    flexGrow: 1,
    textAlign: 'end'
}));

export const ChildrenContainer = styled(Box)<BoxProps>(() => ({
    width: '100%',
    padding: '2rem 0'
}));
