import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Switch from '@mui/material/Switch';

const Cookie: React.FC = () => (

    <CookieConsent
        location="bottom"
        buttonText="Aceptar cookies"
        cookieName="cookiesTecnicas"
        hideOnAccept
        expires={30}
        visible="byCookieValue"
        style={{
            background: 'white', color: 'black', borderTop: '0.25px solid black'
        }}
        buttonStyle={{
            background: '#757575', color: 'white', fontSize: '13px', borderRadius: '1.5em', padding: '10px', marginBottom: '1em'
        }}
    >
        <span style={{ margin: '1.5em 0 0 1em ', display: 'inline-block' }}>
            Platform 2.0 usará unicamente las cookies necesarias para su funcionamiento.
            No usará cookies de terceros.
        </span>
        <Switch disabled defaultChecked color="default" style={{ color: 'grey' }} />
    </CookieConsent>

);

export default Cookie;
