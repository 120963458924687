import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

export const FormSectorBox = styled(Box)<BoxProps>(({ theme }) => ({
    padding: '2rem 0',
    borderBottom: '2px solid',
    borderColor: alpha(theme.palette.primary.main, 0.6),
    backgroundClip: 'padding-box'
}));

export const Dummy = () => {};
