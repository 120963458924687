import React from 'react';

import Box from '@mui/material/Box';

import Table from '../../components/molecules/roles/table';

const Roles: React.FC = () => (
    <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
        <Table />
    </Box>
);

export default Roles;
