import React from 'react';
import { useParams } from 'react-router-dom';

import Table from '../../components/molecules/data/table';
import { URIContext } from '../../utils/URIContext';

// import useAxios from '../../hooks/useAxios';

const Data: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const baseURI = '/api/base/'.concat(id || '');

    // const { response } = useAxios({ url: `${baseURI}/name`, method: 'get' });

    return (
        <URIContext.Provider value={{ baseURI }}>
            {id && <Table key={`table-${id}`} structureId={id} />}
        </URIContext.Provider>
    );
};

export default Data;
