import styled from 'styled-components';
import Panel from '../../components/atoms/structure/panel/styles';

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    gap: 1em;
    width: 100%;
    padding: 2rem;
 `;

export const AddButton = styled.div``;

export const AddButtonPanel = styled(Panel)`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10em;
    
`;
