import React, {
    useState,
    useEffect,
    startTransition,
    createContext
} from 'react';

import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import Topbar from '../../components/layout/topbar';
import PlatformCard from './card';
import { AddButtonIcon } from '../../components/atoms/control/iconButton';

import NewForm from '../../components/molecules/platform/form/New';
// import Modal from '../../components/atoms/structure/popup';
import useAxios from '../../hooks/useAxios';
import useAxiosFunction from '../../hooks/useAxiosFunction';

import '../../styles/components/authentication/platformSelector.scss';
import * as S from './styles';

const baseURI = '/api/platforms';

export interface IPlat {
    name: string;
    description: string;
    subtitle: string;
    isLocked: boolean;
    isHidden: boolean;
    isFilteredByClassifier: boolean;
    [x: string]: any;
}

interface IIdPlat extends IPlat {
    _id: string;
}

export const EditableContext = createContext<Array<string>>([]);

const Platforms: React.FC<{}> = () => {
    const [platforms, setPlatforms] = useState<Array<IIdPlat> | []>([]);
    const [editable, setEditable] = useState<Array<string>>([]);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [shownPlatforms, setShownPlatforms] = useState<Array<IIdPlat> | []>([]);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>('');

    const { response, refetch } = useAxios({ url: baseURI, method: 'get' });
    const { response: responseAccess, axiosFetch } = useAxiosFunction();
    const {
        response: editableResponse,
        refetch: editableRefetch
    } = useAxios({ url: `${baseURI}/editable`, method: 'get' });
    const navigate = useNavigate();

    const printCard = (platform: IIdPlat) => (
        <PlatformCard key={platform._id} initialPlatform={platform} updater={refetch} />
    );

    const filterPlatforms = () => {
        startTransition(() => {
            if (searchInput) {
                const copyPlatforms: Array<IIdPlat> = platforms.filter((
                    plat: IIdPlat
                ) => plat.name.toLowerCase().includes(searchInput.toLowerCase()));

                setShownPlatforms(() => copyPlatforms);
            } else {
                setShownPlatforms(() => platforms);
            }
        });
    };

    useEffect(() => {
        filterPlatforms();
    }, [searchInput, platforms]);

    useEffect(() => {
        editableRefetch();
    }, [platforms.length]);

    useEffect(() => {
        if (response) {
            setPlatforms(() => response.platforms);
            if (response.isSuperAdmin) {
                setIsSuperUser(response.isSuperAdmin);
            }
        } else {
            setPlatforms([]);
        }
    }, [response]);

    const requestAccess = (platformId: string) => {
        const callURI = baseURI.concat('/access/', platformId);
        axiosFetch({ url: callURI, method: 'get' });
    };

    useEffect(() => {
        if (responseAccess) {
            navigate('/');
        }
    }, [responseAccess]);

    useEffect(() => {
        if (!isSuperUser && platforms && Array.isArray(platforms) && platforms.length === 1) {
            requestAccess(platforms[0]._id);
        }
    }, [isSuperUser, platforms]);

    useEffect(() => {
        if (editableResponse) {
            setEditable(() => editableResponse);
        }
    }, [editableResponse]);

    return (
        <div style={{
            flexGrow: 1, display: 'flex', flexDirection: 'column'
        }}
        >
            <Topbar />
            <S.Page>
                <S.Head>
                    {response && response.username && (
                        <S.Title>
                            PLATAFORMAS DE
                            {' '}
                            {response.username.toUpperCase()}
                        </S.Title>
                    )}
                    <TextField
                        fullWidth
                        id="search"
                        value={searchInput}
                        variant="outlined"
                        onChange={(e) => { setSearchInput(e.target.value); }}
                        sx={{
                            marginTop: 4,
                            height: '3.5em',
                            backgroundColor: '#dadde0',
                            color: 'grey',
                            borderRadius: '25px',

                            borderColor: '#dadde0',
                            '& .MuiOutlinedInput-root': {
                                '& > fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <div style={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'
                                }}
                                >
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ marginLeft: 20 }} />
                                    </InputAdornment>
                                </div>
                            )
                        }}
                    />
                    {searchInput && (
                        <div style={{ margin: '1rem 0' }}>
                            {shownPlatforms.length}
                            {' '}
                            resultados
                        </div>

                    )}
                </S.Head>

                <S.Container>
                    <EditableContext.Provider value={editable}>
                        {shownPlatforms && shownPlatforms.map((v: any) => printCard(v))}
                    </EditableContext.Provider>
                    <S.AddButtonPanel>
                        {isSuperUser && (
                            <AddButtonIcon
                                iconSize="4x"
                                functionHandler={() => setPopupOpen(!popupOpen)}
                            />
                        )}
                    </S.AddButtonPanel>
                </S.Container>

                {popupOpen && (
                    <NewForm
                        onClose={() => { setPopupOpen(false); }}
                        onSubmit={() => { refetch(); setPopupOpen(false); }}
                        open={popupOpen}
                        title="Nueva Plataforma"
                    />
                )}
            </S.Page>
        </div>
    );
};

export default Platforms;
