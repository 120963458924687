import React, {
    useState,
    useEffect,
    startTransition,
    useMemo,
    useContext
} from 'react';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Modal, { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import {
    IUser, IRole
} from '../../../../types/user';
import StyledContainer, {
    StyledPropertyContainer,
    StyledPropertyBox,
    StyledPropertyTextField,
    StyledPropertyFieldBox,
    StyledButton,
    StyledForm,
    StyledChangePassword,
    StyledConfirmationTitle
} from './styles';

import { UserContext } from '../../../../App';

export interface IProps extends Omit<ModalProps, 'onSubmit'> {
    userId: string;
    onSubmit?: () => void;
    onClose: () => void;
}

type propertyType = { key: string, value: string, uid: string };

const EditForm: React.FC<IProps> = ({ userId, onSubmit, ...rest }) => {
    const [roles, setRoles] = useState<Array<IRole>>();
    const [role, setRole] = useState<string>();
    const [adminPass, setAdminPass] = useState<string>();
    const [newPass, setNewPass] = useState<string>();
    const [confirmPass, setConfirmPass] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);
    const [user, setUser] = useState<IUser | undefined>();
    const [properties, setProperties] = useState<Array<propertyType>>([]);
    const [rootFolderSelector, setRootFolderSelector] = useState<string>('');

    const { response: foldersResponse, axiosFetch: foldersFetch } = useAxiosFunction();

    const { platform } = useContext(UserContext);

    const changePassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setNewPass(() => e.target.value);
        });
    };

    const confirmPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setConfirmPass(e.target.value);
            setConfirmPass(() => e.target.value);
        });
    };

    const adminPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        startTransition(() => {
            setAdminPass(() => e.target.value);
        });
    };

    function equalPasswords() {
        if (newPass === confirmPass && (newPass !== '' && confirmPass !== '')) {
            return true;
        }
        return false;
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeRole = (
        e: SelectChangeEvent<string>
    ) => {
        setRole(e.target.value);
    };

    const { response: rolesResponse, axiosFetch: roleFetch } = useAxiosFunction();
    const { response: platformResponse, axiosFetch: platformFetch } = useAxiosFunction();
    const { axiosFetch: axiosPutUser } = useAxiosFunction();
    const { axiosFetch: fetchPassword } = useAxiosFunction();

    const handleSubmit = async () => {
        if (!platform || !user) { return; }
        await axiosPutUser({
            url: `/api/platforms/${platform}/users/${user._id}`,
            method: 'put',
            data: {
                properties,
                role,
                rootFolder: rootFolderSelector
            }
        });
        onSubmit?.();
        handleClose();
    };

    const handleAddProperty = () => {
        setProperties((prev) => [...prev, {
            key: 'nombre',
            value: 'Valor',
            uid: Math.floor(Math.random() * 100000).toString()
        }]);
    };

    const handleChangePropertyValue = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        if (properties) {
            startTransition(() => {
                const propCopy = properties;
                propCopy[index].value = event.target.value;
                setProperties(() => [...propCopy]);
            });
        }
    };
    const handleChangePropertyKey = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        if (properties) {
            startTransition(() => {
                const propCopy = properties;
                propCopy[index].key = event.target.value;

                setProperties(() => [...propCopy]);
            });
        }
    };

    const handleRemoveProperty = (index: number, key: string) => {
        if (properties) {
            const propCopy = properties.filter((
                val, i
            ) => index !== i && key !== val.key);

            setProperties(() => [...propCopy]);
        }
    };

    const handleChangePass = async () => {
        // e.preventDefault();
        if (platform) {
            try {
                await fetchPassword({
                    url: `/api/platforms/${platform}/users/${userId}/passwordReset`,
                    method: 'put',
                    data: {
                        adminPassword: adminPass,
                        confirmPassword: confirmPass,
                        newPassword: newPass
                    }
                });
                handleClose();
            } catch (err) {
                let message = 'Error';
                if (err instanceof Error) {
                    message = err.message;
                }
                console.warn(message);
            }
        }
    };

    const { response: userResponse, axiosFetch: userFetch } = useAxiosFunction();

    const propertiesValid = useMemo(() => {
        if (!properties) { return true; }
        const propNames = properties.map(({ key }) => key);
        const uniqueNames = [...new Set(propNames)];
        if (uniqueNames.length !== propNames.length) { return false; }
        const allCheck = properties.every((
            prop
        ) => prop.key.match('^[a-z]+$'));

        if (allCheck) { return true; }
        return false;
    }, [JSON.stringify(properties)]);

    const updateProperties = (addedProperties: any) => {
        if (!addedProperties) { setProperties(() => []); return; }
        if (!Array.isArray(addedProperties) || addedProperties.length !== 1) {
            setProperties(() => []);
            return;
        }

        const parsedProperties = addedProperties[0].properties.map((
            v: { key: string, value: string }
        ) => ({
            ...v,
            uid: Math.floor(Math.random() * 100000).toString()
        }));
        setProperties(() => parsedProperties);
    };

    const updateRole = (hasAccessTo: any) => {
        if (!hasAccessTo) { setRole(() => ''); return; }
        if (!Array.isArray(hasAccessTo) || hasAccessTo.length !== 1) {
            setRole(() => '');
            return;
        }
        setRole(() => hasAccessTo[0].withRole);
    };

    const updateRootFolder = (hasAccessTo: any) => {
        if (!hasAccessTo) { setRootFolderSelector(() => ''); return; }
        if (!Array.isArray(hasAccessTo) || hasAccessTo.length !== 1) {
            setRootFolderSelector(() => '');
            return;
        }
        if (!hasAccessTo[0].rootFolder || !hasAccessTo[0].rootFolder.name) {
            setRootFolderSelector(() => '');
            return;
        }

        setRootFolderSelector(() => hasAccessTo[0].rootFolder.name);
    };

    useEffect(() => {
        if (userResponse) {
            setUser(() => userResponse);
            updateProperties(userResponse.addedProperties);
            updateRole(userResponse.hasAccessTo);
            updateRootFolder(userResponse.hasAccessTo);
        }
    }, [userResponse]);

    useEffect(() => {
        if (platform) {
            userFetch({ method: 'get', url: `/api/platforms/${platform}/users/${userId}?populate=true` });
            roleFetch({ method: 'get', url: `/api/platforms/${platform}/roles` });
        }
    }, [platform]);

    useEffect(() => {
        setRoles(() => []);
        if (rolesResponse) { setRoles(() => rolesResponse); }
    }, [rolesResponse]);

    const platformObj = useMemo(() => {
        if (platformResponse) {
            return platformResponse;
        }
        return undefined;
    }, [platformResponse]);

    const checkIfKeyIsReadOnly = (key: string) => {
        if (!platformObj) { return true; }

        if (!platformObj.preferences || !platformObj.preferences.defaultUserAttributes) {
            return false;
        }

        if (!Array.isArray(platformObj.preferences.defaultUserAttributes)) { return false; }

        const { defaultUserAttributes } = platformObj.preferences;
        return defaultUserAttributes.includes(key);
    };

    useEffect(() => {
        if (platform) {
            foldersFetch({
                url: `/api/platforms/${platform}/folders`,
                method: 'get'
            });

            platformFetch({
                url: `/api/platforms/${platform}`,
                method: 'get'
            });
        }
    }, [platform]);

    const rootFolders = useMemo(() => {
        if (foldersResponse && Array.isArray(foldersResponse)) {
            return foldersResponse;
        }

        return undefined;
    }, [foldersResponse]);

    return (
        <>
            {user && (
                <Modal
                    onSubmit={handleSubmit}
                    isDisabledSubmit={!propertiesValid}
                    alertText={propertiesValid
                        ? ''
                        : 'Los  nombres en propiedad deben ser no nulos, únicos y solo pueden contener letras minúsculas'}
                    {...rest}
                >
                    <StyledContainer>
                        {roles && roles.length > 0 && role && (
                            <FormControl fullWidth>
                                <InputLabel id="selector"> Rol </InputLabel>
                                <Select
                                    labelId="selector"
                                    value={role}
                                    label="Rol"
                                    onChange={handleChangeRole}
                                >

                                    {roles.map((option, index: number) => (
                                        <MenuItem
                                            key={`${option._id}-${index.toString()}`}
                                            value={option._id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </StyledContainer>
                    {rootFolders && (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="selector-root"> Carpeta Raíz </InputLabel>
                            <Select
                                labelId="root-folder-selector"
                                value={rootFolderSelector}
                                label="Carpeta raiz"
                                onChange={(e) => {
                                    setRootFolderSelector(e.target.value as string);
                                }}
                            >
                                {rootFolders.map((option) => (
                                    <MenuItem
                                        key={option.name || ''}
                                        value={option.name}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    )}
                    {properties && (
                        <StyledPropertyContainer>
                            <Typography variant="h5">
                                Propiedades del usuario
                            </Typography>
                            {properties.map(({ key, value, uid }, index: number) => (
                                <span key={uid}>
                                    <StyledPropertyBox>
                                        <StyledPropertyFieldBox>
                                            <StyledPropertyTextField
                                                variant="outlined"
                                                defaultValue={key}
                                                disabled={checkIfKeyIsReadOnly(key)}
                                                onChange={(e) => handleChangePropertyKey(e, index)}
                                            />
                                            <StyledPropertyTextField
                                                variant="outlined"
                                                defaultValue={value}
                                                onChange={(
                                                    e
                                                ) => handleChangePropertyValue(e, index)}
                                            />
                                        </StyledPropertyFieldBox>
                                        <IconButton
                                            onClick={() => handleRemoveProperty(index, key)}
                                            color="primary"
                                            component="span"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </StyledPropertyBox>
                                </span>
                            ))}
                        </StyledPropertyContainer>

                    )}
                    <Button onClick={handleAddProperty} variant="contained"> Añadir </Button>
                    <StyledChangePassword>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => { setOpen(true); }}
                        >
                            Modificar Contraseña
                        </Button>
                    </StyledChangePassword>
                    <StyledForm>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle sx={{ height: '35' }}>Confirmación</DialogTitle>
                            <DialogContent>
                                <StyledConfirmationTitle>
                                    <StyledChangePassword>
                                        <TextField
                                            variant="outlined"
                                            name="adminPassword"
                                            label="Admin Password"
                                            inputProps={{
                                                type: 'password', minlength: 8, pattern: '[a-z]{1,15}'
                                            }}
                                            fullWidth
                                            onChange={(e) => { adminPassword(e); }}
                                        />
                                    </StyledChangePassword>
                                    <StyledChangePassword>
                                        <TextField
                                            variant="outlined"
                                            name="password"
                                            label="Password"
                                            inputProps={{
                                                type: 'password', minlength: 8, pattern: '[a-z]{1,15}'
                                            }}
                                            fullWidth
                                            onChange={(e) => { changePassword(e); }}
                                        />
                                    </StyledChangePassword>
                                </StyledConfirmationTitle>
                                <StyledChangePassword>
                                    <TextField
                                        variant="outlined"
                                        name="confirmPassword"
                                        label="Repeat Password"
                                        inputProps={{
                                            type: 'password', minlength: 8, pattern: '[a-z]{1,15}'
                                        }}
                                        fullWidth
                                        onChange={(e) => { confirmPassword(e); }}
                                    />
                                </StyledChangePassword>
                            </DialogContent>
                            <DialogActions>
                                <DialogContentText>
                                    <strong> Atención ! </strong>
                                    La contraseña debe tener mínimo 8 caracteres,
                                    conteniendo al menos una letra mayúscula y un número.
                                </DialogContentText>

                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { setOpen(false); }}
                                >
                                    Cancelar
                                </StyledButton>
                                <StyledButton
                                    type="button"
                                    variant="contained"
                                    id="submit"
                                    color="error"
                                    onClick={() => { handleChangePass(); }}
                                    disabled={!equalPasswords()}
                                >
                                    Guardar
                                </StyledButton>
                            </DialogActions>
                        </Dialog>
                    </StyledForm>
                </Modal>
            )}
        </>
    );
};

export default EditForm;
