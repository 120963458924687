import React, {
    useState,
    useEffect,
    useContext,
    useRef
} from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ResetFlagContext } from '.';

interface IFilterProps {
    name: string,
    apiName: string,
    index: number,
    onChange: (filterValue: string, index: number | string) => void,
}
const NumberFilter: React.FC<IFilterProps> = ({
    index, name, apiName, onChange
}) => {
    const [filterMode, setFilterMode] = useState<string>('eq');
    const [filterValue, setFilterValue] = useState<number | undefined>(undefined);
    const resetFlag: number = useContext(ResetFlagContext);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChangeMode = (event: SelectChangeEvent) => {
        setFilterMode(event.target.value);
    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const num = Number(event.target.value);
        if (Number.isNaN(num) || !Number.isFinite(num)) {
            setFilterValue(() => (undefined));
        } else {
            setFilterValue(num);
        }
    };

    useEffect(() => {
        if (filterMode && filterValue) {
            onChange(`${apiName}_${filterMode}_${filterValue}`, index);
        } else {
            onChange('', index);
        }
    }, [filterMode, filterValue]);

    useEffect(() => {
        if (resetFlag) {
            setFilterMode('eq');
            setFilterValue(() => (undefined));
            onChange('', index);
            if (inputRef && inputRef.current && inputRef.current.value) {
                inputRef.current.value = '';
            }
        }
    }, [resetFlag]);

    return (
        <Box sx={{
            p: 2,
            border: '2px solid lightgray',
            borderRadius: '1rem',
            display: 'flex',
            gap: '1rem'
        }}
        >
            <FormControl>
                <InputLabel id={`number-filter-${apiName}-label`}>
                    {name}
                </InputLabel>
                <Select
                    labelId={`number-filter-${apiName}-label`}
                    id={`number-filter-${apiName}`}
                    value={filterMode}
                    label={name}
                    onChange={handleChangeMode}
                >
                    <MenuItem value="eq">Igual a:</MenuItem>
                    <MenuItem value="lt">Menor que:</MenuItem>
                    <MenuItem value="gte">Mayor/igual que:</MenuItem>
                </Select>
            </FormControl>
            { filterValue }
            <TextField
                inputRef={inputRef}
                id={`${apiName}-number`}
                label={name}
                value={filterValue}
                type="number"
                InputLabelProps={{
                    shrink: true
                }}
                onChange={handleChangeValue}
            />
        </Box>
    );
};

export default NumberFilter;
