import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledSidebarContainer = styled(Box)<BoxProps>(() => ({
    height: '90%',
    width: '6rem',
    backgroundColor: '#505050',
    borderRadius: '0 0 50px 0',
    display: 'inline-flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
}));

interface ICustomButtonProps extends ButtonProps {
    selected?: boolean;
}

export const StyledButton = styled(Button)<ICustomButtonProps>(({ selected, theme }) => ({
    height: '6rem',
    width: '6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background 0.2s ease-in',
    borderRadius: '0',
    backgroundColor: selected ? alpha(theme.palette.primary.main, 0.5) : '',
    '&:hover': {
        cursor: 'pointer',
        background: '#707070'
    },
    '&& .MuiTouchRipple-child': {
        backgroundColor: '#505050'
    }
}));
