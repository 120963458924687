import React, { useState, useEffect } from 'react';
import Form from '.';
import { IProps as ModalProps } from '../../sideModal';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { IBBDD, IBaseBBDD } from '../../../../types/bbdd';

export interface IProps extends ModalProps {
    id: string;
}

const EditForm: React.FC<ModalProps> = ({ onSubmit, id, ...rest }) => {
    const [structure, setStructure] = useState<IBBDD>();

    const {
        response, loading, error, axiosFetch
    } = useAxiosFunction();

    const {
        axiosFetch: postAxiosFetch
    } = useAxiosFunction();

    const onSubmitNewAction = async (editedStructure: IBBDD | IBaseBBDD) => {
        try {
            await postAxiosFetch({
                url: `/api/base/${id}`,
                method: 'put',
                data: { ...editedStructure }
            });
            onSubmit?.();
        } catch (err) {
            console.warn(err.message);
        }
    };

    useEffect(() => {
        axiosFetch({ url: `/api/base/${id}`, method: 'get' });
    }, []);

    useEffect(() => {
        setStructure(response);
    }, [response]);

    return (
        <>
            {!loading && !error && structure && (
                <Form
                    onSubmit={onSubmitNewAction}
                    structure={structure}
                    {...rest}
                />
            )}
        </>
    );
};

export default EditForm;
