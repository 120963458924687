import React from 'react';
import FontAwesome, { FontAwesomeSize } from 'react-fontawesome';

import * as S from './styles';

interface IProps {
    iconName: string;
    functionHandler?: () => void;
    className?: string;
    iconColor?: string;
    iconSize?: FontAwesomeSize;
   // iconAlign?: string;
}

interface IPropsFormIcons {
    functionHandler?: () => void;
    iconSize?: FontAwesomeSize;
}

const ButtonIcon: React.FC<IProps> = ({
    iconName, functionHandler, className, iconColor, iconSize
}: IProps) => (
    <S.IconButton color={iconColor} className={className} onClick={functionHandler}>
        <FontAwesome size={iconSize as FontAwesomeSize} name={iconName} />
    </S.IconButton>
);

export const AddButtonIcon: React.FC<IPropsFormIcons> = ({
    functionHandler, iconSize
}: IPropsFormIcons) => (
    <S.IconAddButton onClick={functionHandler}>
        <FontAwesome size={iconSize as FontAwesomeSize} name="plus-circle" />
    </S.IconAddButton>
);

export const CancelButtonIcon: React.FC<IPropsFormIcons> = ({
    functionHandler, iconSize
}: IPropsFormIcons) => (
    <S.IconCancelButton onClick={functionHandler}>
        <FontAwesome size={iconSize as FontAwesomeSize} name="times-circle" />
    </S.IconCancelButton>
);

ButtonIcon.defaultProps = {
    className: '', iconColor: '', iconSize: 'lg', functionHandler: () => null
};

const formIconButtonsDefaultProps = { iconSize: '3x' as FontAwesomeSize, functionHandler: () => null };
AddButtonIcon.defaultProps = formIconButtonsDefaultProps;
CancelButtonIcon.defaultProps = formIconButtonsDefaultProps;

export default ButtonIcon;
