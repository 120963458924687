import React from 'react';

import Modal, { ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import {
    StyledContainer,
    StyledHeader,
    StyledActionContainer,
    StyledButtonContainer,
    StyledContentContainer,
    AlertBox
} from './styles';

export interface IProps extends Omit<ModalProps, 'children'> {
    title?: string;
    isDisabledSubmit?: boolean;
    onSubmit?: () => void;
    onCancel?: () => void;
    alertText?: string;
    children?: React.ReactNode;
}

const SideModal: React.FC<IProps> = ({
    title, alertText, onClose, onSubmit, onCancel, isDisabledSubmit = false, children, ...rest
}: IProps) => (
    <Modal
        {...rest}
        onClose={(e) => {
            onCancel?.();
            onClose?.(e, 'backdropClick');
        }}
    >
        <StyledContainer>
            <StyledHeader>
                <Typography noWrap variant="h3">
                    {title}
                </Typography>
            </StyledHeader>
            <StyledContentContainer>
                { children }
            </StyledContentContainer>
            <StyledActionContainer>

                <StyledButtonContainer>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            onCancel?.();
                            onClose?.(e, 'backdropClick');
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={isDisabledSubmit}
                        onClick={(e) => {
                            onSubmit?.();
                            onClose?.(e, 'backdropClick');
                        }}
                    >
                        Guardar
                    </Button>
                </StyledButtonContainer>
                { alertText && (
                    <AlertBox>
                        <Alert severity="error">
                            { alertText }
                        </Alert>
                    </AlertBox>
                ) }
            </StyledActionContainer>
        </StyledContainer>
    </Modal>
);

export default SideModal;
