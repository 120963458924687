import React, {
    useEffect, useState, useMemo
} from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    Field, ResponsiveFlex, ResponsiveHeader, ResponsivePage
} from '../docs/styles';
import Attachement from '../../components/molecules/docs/Attachement';
import Header from '../../components/molecules/docs/Header';

import useAxiosFunction from '../../hooks/useAxiosFunction';

type DocType = { _id: string, name: string, createdAt: Date };

const Attachements: React.FC = () => {
    const [structure, setStructure] = useState<{ _id: string, name: string }>({ _id: '', name: '' });
    const [files, setFiles] = useState<Array<DocType>>([]);
    const { structureId } = useParams();
    const [searchInput, setSearchInput] = useState<string>('');

    const { response: structureResponse, axiosFetch: axiosFetchStructure } = useAxiosFunction();
    const { response: filesResponse, axiosFetch: axiosFetchFiles } = useAxiosFunction();

    const displayFiles = useMemo(() => {
        if (searchInput) {
            return files.filter((
                file
            ) => file.name.toLowerCase().includes(searchInput.toLowerCase()));
        }
        return files;
    }, [searchInput, files]);

    const fetchStructure = () => {
        if (structureId) {
            const urlQuery = `/api/structures/${structureId}`;

            axiosFetchStructure({
                url: urlQuery,
                method: 'get'
            });
        }
    };

    const fetchFiles = () => {
        if (structureId) {
            const urlQuery = `/api/structures/${structureId}/attachements`;

            axiosFetchFiles({
                url: urlQuery,
                method: 'get'
            });
        }
    };

    useEffect(() => {
        if (structureResponse) {
            setStructure(structureResponse);
        } else {
            setStructure({ _id: '', name: '' });
        }
    }, [filesResponse]);

    useEffect(() => {
        if (filesResponse) {
            setFiles(filesResponse);
        } else {
            setFiles([]);
        }
    }, [filesResponse]);

    useEffect(() => {
        fetchFiles();
        fetchStructure();
    }, []);

    return (
        <>
            <Field style={{
                backgroundColor: '#dadde0',
                marginLeft: '-0.1em',
                marginTop: '0em',
                height: '6em',
                width: '90%',
                WebkitBorderBottomRightRadius: '2em'
            }}
            >
                <TextField
                    id="search"
                    placeholder="Buscar por nombre de documento"
                    value={searchInput}
                    variant="outlined"
                    onChange={(e) => { setSearchInput(e.target.value); }}
                    sx={{
                        width: '78%',
                        backgroundColor: 'white',
                        borderRadius: '2em',
                        borderColor: '#dadde0',
                        '& .MuiOutlinedInput-root': {
                            '& > fieldset': {
                                border: 'none'
                            }
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <div>
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            </div>
                        )
                    }}
                />
            </Field>
            <ResponsivePage sx={{ flexDirection: 'column', width: '100%' }}>
                {structure && (
                    <div style={{
                        fontSize: '2em',
                        margin: '2rem'
                    }}
                    >
                        {structure.name}
                    </div>
                )}
                <div>
                    <ResponsiveHeader sx={{ top: '10px', width: '100%' }}>
                        <Header />
                    </ResponsiveHeader>
                    {structure && structure._id && (
                        <ResponsiveFlex sx={{ flexDirection: 'column', width: '100%' }}>
                            <div style={{
                                width: '100%'
                            }}
                            >
                                {displayFiles.map((doc: DocType, index: number) => (
                                    <Attachement
                                        key={`${doc._id}-${index.toString()}`}
                                        attachementId={doc._id}
                                        attachementName={doc.name}
                                        structureId={structure._id}
                                        createdAt={doc.createdAt}
                                    />
                                ))}
                            </div>
                        </ResponsiveFlex>
                    )}
                </div>
            </ResponsivePage>
        </>
    );
};

export default Attachements;
