import { styled, alpha } from '@mui/material';

import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export const StyledDocContainer = styled(Box)<BoxProps>(({ theme }) => ({
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
    borderRadius: '2000rem',
    padding: '0.5rem 1.5rem',
    margin: '1em 2rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4em',
    width: '95%'
}));

export const StyledFolderLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'black',
    flexGrow: 1

}));

export const StyledHeaderBox = styled(Box)(() => ({
    textDecoration: 'none',
    color: 'black',
    flexGrow: 1

}));

export const ResponsiveDocs = styled('div')({
    '@media(min-width: 780px)': {

    },
    '@media(max-width: 781px)': {
        width: '23rem'
    }
});

export const StyledTextBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
}));

export const StyledDateBox = styled(Box)<BoxProps>(() => ({
    alignItems: 'center',
    gap: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
}));

export const StyledActionBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    justifyContent: 'end'
}));

export const StyledUploadContainer = styled(Box)<BoxProps>(() => ({
    height: '7rem',
    padding: '0.5rem 1.5rem',
    margin: '0 2rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const StyledUploadBox = styled(Box)<BoxProps>(() => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
}));

export const StyledPathContainer = styled(Box)<BoxProps>(() => ({
    padding: '0.5rem 1.5rem'
}));

export const StyledPathLink = styled(Link)(({ theme }) => ({
    display: 'inline',
    marginRight: '1rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}));

export const StylePathLinkText = styled(Typography)<TypographyProps>(() => ({
    display: 'inline',
    color: 'black'
}));
