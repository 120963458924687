import React, { useState, useContext, useEffect } from 'react';

import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { ResetFlagContext } from '.';

interface IFilterProps {
    name: string,
    apiName: string,
    index: number,
    onChange: (filterValue: string, index: number | string) => void,
}
const DateFilter: React.FC<IFilterProps> = ({
    index, name, apiName, onChange
}) => {
    const [filterFromValue, setFilterFromValue] = useState<DateTime | null>(null);
    const [filterToValue, setFilterToValue] = useState<DateTime | null>(null);
    const resetFlag: number = useContext(ResetFlagContext);

    const handleChangeFrom = (value: DateTime | null) => {
        if (!value || !value.isValid) {
            onChange('', `${index}_f`);
            setFilterFromValue(null);
            return;
        }
        const resetValue: DateTime = value.set({ hour: 0, minute: 0, second: 0 });
        if (!resetValue.isValid) {
            onChange('', `${index}_f`);
            setFilterFromValue(null);
            return;
        }
        onChange(`${apiName}_gte_${resetValue.toJSDate().toISOString()}`, `${index}_f`);
        setFilterFromValue(resetValue);
    };

    const handleChangeTo = (value: DateTime | null) => {
        if (!value || !value.isValid) {
            onChange('', `${index}_t`);
            setFilterToValue(null);
            return;
        }
        const resetValue: DateTime = value.set({ hour: 0, minute: 0, second: 0 });
        if (!resetValue.isValid) {
            onChange('', `${index}_t`);
            setFilterToValue(null);
            return;
        }
        onChange(`${apiName}_lt_${resetValue.toJSDate().toISOString()}`, `${index}_t`);
        setFilterToValue(resetValue);
    };

    useEffect(() => {
        if (resetFlag) {
            handleChangeTo(null);
            handleChangeFrom(null);
        }
    }, [resetFlag]);

    return (
        <Box sx={{
            p: 2,
            border: '2px solid lightgray',
            borderRadius: '1rem',
            display: 'flex',
            gap: '1rem'
        }}
        >
            <DesktopDatePicker
                label={`${name} desde:`}
                inputFormat="dd/MM/yyyy"
                value={filterFromValue}
                onChange={handleChangeFrom}
                renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
                label={`${name} hasta:`}
                inputFormat="dd/MM/yyyy"
                value={filterToValue}
                onChange={handleChangeTo}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );
};

export default DateFilter;
