import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';

import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import useAxiosFunction from '../../../hooks/useAxiosFunction';

// import Logo from '../../../assets/imgs/logos/Platform360.png';

import * as S from './styles';

const Login: React.FC<{}> = () => {
    const [emailInput, setEmail] = useState('');
    const [emailResetPassword, setEmailResetPassword] = useState('');
    const [passwordInput, setPassword] = useState('');
    const navigate = useNavigate();

    const { response, error, axiosFetch } = useAxiosFunction();
    const { axiosFetch: fetchEmail } = useAxiosFunction();
    const [open, setOpen] = useState<boolean>(false);

    const { REACT_APP_LOGO: logo } = process.env;

    const requestLogin = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (emailInput === '' || passwordInput === '') {
            return;
        }

        await axiosFetch({
            url: '/api/auth/login',
            method: 'post',
            data: {
                email: emailInput,
                password: passwordInput
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSendEmail = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            await fetchEmail({
                url: '/api/auth/requestResetPassword',
                method: 'post',
                data: {
                    email: emailResetPassword
                }
            });
            handleClose();
        } catch (err) {
            let message = 'Error';
            if (err instanceof Error) {
                message = err.message;
            }
            console.warn(message);
        }
    };

    useEffect(() => {
        if (response) {
            navigate('/');
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setPassword(() => '');
        }
    }, [error]);

    return (
        <S.Container>
            <S.Box elevation={3}>
                <Box display="flex" justifyContent="center" sx={{ width: '100%', height: '30%' }}>
                    <S.Logo src={`/logos/${logo}`} alt="Logo Platform" />
                </Box>
                <S.Stack spacing={2}>
                    <S.Form onSubmit={(e) => { requestLogin(e); }}>
                        <S.Stack spacing={2} $fullWidth>
                            <S.TextField
                                required
                                id="login-email-input"
                                label="Email"
                                variant="outlined"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <S.TextField
                                required
                                id="login-password-input"
                                label="Password"
                                value={passwordInput}
                                variant="outlined"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <S.Button
                                type="submit"
                                variant="contained"
                                endIcon={<SendIcon />}
                            >
                                Login
                            </S.Button>
                        </S.Stack>
                    </S.Form>
                    <S.Button
                        variant="text"
                        color="secondary"
                        sx={{
                            ':hover': {
                                color: 'blue',
                                textDecorationLine: 'underline'
                            }
                        }}
                        onClick={() => { setOpen(true); }}
                    >
                        ¿Has olvidado tu contraseña?
                    </S.Button>
                    <S.StyledForm>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle sx={{ height: '35' }}> Reestablecer contraseña </DialogTitle>
                            <DialogContent>
                                <S.StyledTitle>
                                    <S.StyledEmail>
                                        <S.TextField
                                            variant="outlined"
                                            name="email"
                                            label="Email"
                                            fullWidth
                                            onChange={(e) => setEmailResetPassword(e.target.value)}
                                        />
                                    </S.StyledEmail>
                                </S.StyledTitle>
                            </DialogContent>
                            <DialogActions>
                                <DialogContentText>
                                    Se le enviará una contraseña a la dirección
                                    de correo electrónico indicada.
                                </DialogContentText>

                                <S.StyledButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { setOpen(false); }}
                                >
                                    Cancelar
                                </S.StyledButton>
                                <S.StyledButton
                                    type="button"
                                    variant="contained"
                                    id="submit"
                                    color="error"
                                    onClick={(e) => { handleSendEmail(e); }}
                                >
                                    Guardar
                                </S.StyledButton>
                            </DialogActions>
                        </Dialog>
                    </S.StyledForm>
                </S.Stack>
            </S.Box>
        </S.Container>
    );
};

export default Login;
