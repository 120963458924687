import React from 'react';

import { StyledFieldIndicatorBox, StyledTypeIndicator } from './styles';

import { IField } from '../../../../types/bbdd';

interface IProps {
    field: IField;
}

const FieldIndicator: React.FC<IProps> = ({ field }) => {
    const renderFieldType = (): string => {
        if (field.fieldType === 'string') { return 'Alfanumérico'; }
        if (field.fieldType === 'number') { return 'Numérico'; }
        if (field.fieldType === 'percentage') { return 'Porcentual'; }
        if (field.fieldType === 'boolean') { return 'Booleano'; }
        if (field.fieldType === 'date') { return 'Fecha'; }
        if (field.fieldType === 'selector') { return 'Selector'; }
        if (field.fieldType === 'file') { return 'Archivo'; }
        if (field.fieldType === 'formula') { return 'Formula'; }
        if (field.fieldType === 'autonumeric') { return 'Autonumérico'; }
        return '';
    };

    return (
        <StyledFieldIndicatorBox>
            {field.fieldName}
            { field.required ? '*' : ''}
            <StyledTypeIndicator>{renderFieldType()}</StyledTypeIndicator>
        </StyledFieldIndicatorBox>
    );
};

export default FieldIndicator;
