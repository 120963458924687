import React, {
    useContext,
    useEffect,
    useCallback,
    useState,
    useMemo
} from 'react';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import ContentHeader from '../../components/atoms/structure/contentHeader';

import useAxiosFunction from '../../hooks/useAxiosFunction';

import { UserContext } from '../../App';

import {
    StyledBox, StyledTable, StyledSearchField
} from './styles';

interface ILog {
    _id: string;
    type: string;
    message: string;
    createdAt: string;
}

const Logs: React.FC<{}> = () => {
    const { platform } = useContext(UserContext);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [searchInput, setSearchInput] = useState<string>('');

    const { response, axiosFetch } = useAxiosFunction();

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const logs: Array<ILog> = useMemo(() => {
        if (!response || !response || !response.logs) { return []; }
        return response.logs;
    }, [response]);

    const displayedLogs: Array<ILog> = useMemo(() => {
        if (!logs) { return []; }
        if (!searchInput) { return logs; }
        return logs.filter((log) => log.message.toLowerCase().includes(searchInput.toLowerCase()));
    }, [logs, searchInput]);

    useEffect(() => {
        if (platform) {
            axiosFetch({
                method: 'get',
                url: `/api/platforms/${platform}/logs`
            });
        }
    }, [platform]);

    const downloadToCsv = useCallback(() => {
        const CSV_HEADERS: Array<string> = ['Tipo', 'Fecha', 'Mensage'];
        const csvHeadersString: string = CSV_HEADERS.join(',').concat('\n');
        const csvDataString: string = displayedLogs
            .map((log: ILog) => {
                const date = new Date(log.createdAt).toISOString().replace(/,/g, ' ');
                const message = log.message.replace(/,/g, ' ');
                return `${log.type},${date},${message}`;
            })
            .join('\n');

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,';
        hiddenElement.href += encodeURI(`${csvHeadersString}${csvDataString}`);
        hiddenElement.target = '_blank';

        hiddenElement.download = `Export_logs_${new Date().toLocaleTimeString()}.csv`;
        hiddenElement.click();
    }, [displayedLogs]);
    return (
        <Box width="100%">
            <Box width="100%" overflow="auto" display="inline-flex" flexDirection="column" gap={0}>
                <ContentHeader
                    title="Logs"
                    counter={logs.length}
                    buttonDisplayed={false}
                    buttonText=""
                    buttonDisabled
                    onClick={() => { }}
                >
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <StyledSearchField
                            fullWidth
                            id="search"
                            value={searchInput}
                            variant="outlined"
                            onChange={(e) => { setSearchInput(e.target.value); }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="contained" onClick={downloadToCsv}>
                                Exportar
                            </Button>
                        </Box>
                    </Box>
                </ContentHeader>

                <StyledBox flexGrow={1}>
                    <TableContainer sx={{ width: '100%', overflowX: 'scroll' }}>

                        <StyledTable displayIndex={2} cellSpacing={0} aria-label="simple table">
                            <TableHead>
                                <TableCell align="center" key="Actions">
                                    <strong>Tipo</strong>
                                </TableCell>
                                <TableCell align="center" key="Actions">
                                    <strong>Fecha</strong>
                                </TableCell>
                                <TableCell align="center" key="Actions">
                                    <strong>Mensage</strong>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {displayedLogs && displayedLogs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((log) => (
                                        <TableRow key={log._id}>
                                            <TableCell align="center">
                                                {log.type}
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(log.createdAt).toLocaleString()}
                                            </TableCell>
                                            <TableCell align="center">
                                                {log.message}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                        </StyledTable>
                    </TableContainer>
                    {displayedLogs && (
                        <TablePagination
                            rowsPerPageOptions={[5, 10]}
                            component="div"
                            count={displayedLogs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </StyledBox>

            </Box>
        </Box>
    );
};

export default Logs;
