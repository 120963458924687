import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

const StyledContainer = styled(Box)<BoxProps>(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginTop: '2rem'
}));

export default StyledContainer;
