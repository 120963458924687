import React, { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { UserContext } from '../../App';

import useAxios from '../../hooks/useAxios';
import useAxiosFunction from '../../hooks/useAxiosFunction';

const Visualization: React.FC<{}> = () => {
    const [uriInput, setUriInput] = useState<string>('');

    const userContext = useContext(UserContext);

    const { response } = useAxios({
        url: userContext.platform ? `/api/platforms/${userContext.platform}` : undefined,
        method: 'get'
    });

    const { axiosFetch } = useAxiosFunction();

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setUriInput(() => event.target.value);
    };

    const persistDirection = async () => {
        await axiosFetch({
            url: `/api/platforms/${userContext.platform}/`,
            method: 'put',
            data: {
                visualizationRoute: uriInput
            }
        });
    };

    useEffect(() => {
        setUriInput(response?.visualizationRoute || '');
    }, [response]);

    return (
        <Box
            sx={{
                width: '100%'
            }}

        >
            <Paper elevation={4} sx={{ padding: '1rem 0', margin: '0.5rem 0' }}>
                <Typography variant="h4" align="center">
                    Configuración de visualización
                </Typography>
            </Paper>
            {!(userContext && userContext.platform)
                ? (<></>)
                : (
                    <Grid container spacing={3} direction="row">
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper
                                elevation={2}
                                sx={{
                                    padding: '1rem'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    align="center"
                                    gutterBottom
                                >
                                    Dirección
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Dirección URL"
                                    value={uriInput}
                                    onChange={handleInputChange}
                                />
                                <Box sx={{
                                    marginTop: '1rem',
                                    textAlign: 'center'
                                }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={persistDirection}
                                    >
                                        Guardar
                                    </Button>
                                </Box>
                            </Paper>

                        </Grid>
                    </Grid>
                )}
        </Box>
    );
};

export default Visualization;
