import React from 'react';

import Typography from '@mui/material/Typography';
import { StyledContentBox, StyledHeaderContainer, StyledMainContainer } from './styles';

export interface IProps {
    title?: string;
    children?: React.ReactNode;
}

const Content: React.FC<IProps> = ({ title, children }: IProps) => (
    <StyledContentBox>
        <StyledHeaderContainer>
            <Typography variant="h2">
                { title }
            </Typography>
        </StyledHeaderContainer>
        <StyledMainContainer>
            {children}
        </StyledMainContainer>
    </StyledContentBox>

);

export default Content;
