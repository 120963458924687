import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import EditForm from '../../../components/molecules/platform/form/Edit';

import useAxiosFunction from '../../../hooks/useAxiosFunction';

import * as S from './styles';
import { EditableContext } from '..';

interface IPlatform {
    _id: string;
    name: string;
    description: string;
    subtitle: string;
    isHidden: boolean;
    isLocked: boolean;
    isFilteredByClassifier: boolean;
    [x: string]: any;
}

interface ICardProps {
    initialPlatform: IPlatform;
    updater: () => void;
}

const baseURI = '/api/platforms';

const PlatformCard: React.FC<ICardProps> = ({
    initialPlatform, updater
}: ICardProps) => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);
    const navigate = useNavigate();
    const editableContext = useContext(EditableContext);

    const { response, axiosFetch } = useAxiosFunction();

    const requestAccess = async () => {
        const callURI = baseURI.concat('/access/', initialPlatform!._id);
        try {
            await axiosFetch({ url: callURI, method: 'get' });

            if (response) {
                navigate('/');
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (editableContext) {
            setEditable(() => editableContext.includes(initialPlatform._id));
        }
    }, [editableContext]);

    if (initialPlatform) {
        return (
            <S.Card>
                { editable && (
                    <IconButton sx={{ top: '10px', right: '15px', position: 'absolute' }} onClick={() => setPopupOpen(true)}>
                        <EditIcon />
                    </IconButton>
                )}

                <S.CardSubtitle>{ initialPlatform.subtitle }</S.CardSubtitle>
                <S.CardHeader>{ initialPlatform.name }</S.CardHeader>
                <S.CardContent>{ initialPlatform.description }</S.CardContent>
                <S.CardActions>
                    <Button variant="contained" onClick={requestAccess} sx={{ width: '100%', padding: '10px 50px' }}>
                        <IconButton sx={{ color: 'white', width: '10px' }}>
                            <ArrowForwardIcon sx={{ color: 'white', right: '20px', position: 'absolute' }} />
                        </IconButton>
                        Acceder
                    </Button>
                </S.CardActions>

                {popupOpen && (
                    <EditForm
                        id={initialPlatform._id}
                        onClose={() => { setPopupOpen(false); }}
                        onSubmit={() => { updater(); setPopupOpen(false); }}
                        open={popupOpen}
                        title="Editar Plataforma"
                    />
                )}
            </S.Card>
        );
    }
    return (<></>);
};

export default PlatformCard;
