import React, { useState, useContext } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Form from '../form/Edit';

import useAxiosFunction from '../../../../hooks/useAxiosFunction';

import { userType } from '.';
import { UserContext } from '../../../../App';

export interface IProps {
    user: userType;
    refetch: () => void;
}

const Row: React.FC<IProps> = ({ user, refetch }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { platform } = useContext(UserContext);

    const { axiosFetch: removeAxiosFunction } = useAxiosFunction();

    const handleRemoveUser = async (id: string | undefined) => {
        if (id && platform) {
            await removeAxiosFunction({
                url: `/api/platforms/${platform}/users/${id}`,
                method: 'delete'

            });
        }

        refetch();
    };

    return (
        <>
            <TableRow key={user._id}>
                <TableCell>
                    <IconButton
                        component="span"
                        onClick={() => { handleRemoveUser(user._id); }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        component="span"
                        onClick={() => { setOpen(true); }}
                    >
                        <EditIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {user.username}
                </TableCell>
                <TableCell>
                    {user.email}
                </TableCell>
            </TableRow>
            {open && user._id && (
                <Form
                    userId={user._id}
                    open={open}
                    onSubmit={refetch}
                    onClose={() => { setOpen(false); }}
                    title="Editar usuario"
                />

            )}
        </>
    );
};

export default Row;
