import { useState, useEffect } from 'react';

import axios from '../utils/axiosIntercepter';

export interface IParams {
    url: string | undefined;
    method: 'get' | 'post' | 'options' | 'put' | 'delete';
    data?: {[x: string]: any};
    headers?: {[x:string]: any};
}

const useAxios = ({
    url, method = 'get', data, headers
}: IParams) => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [reload, setReload] = useState<number>(0);

    const refetch = () => setReload((prev) => prev + 1);

    useEffect(() => {
        const controller = new AbortController();
        const fetchData = async () => {
            try {
                if (url || url === '') {
                    const result = await axios[method](url, {
                        headers,
                        signal: controller.signal,
                        data
                    });
                    setError('');
                    setResponse(result.data || 'Success');
                }
            } catch (err) {
                setResponse(undefined);
                setError(() => err.data || 'A server error has ocurred');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            controller.abort();
        };
    }, [reload, url]);

    return {
        response, error, loading, refetch
    };
};

export default useAxios;
