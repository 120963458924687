// import { styled } from '@mui/material';
// import styled from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';

import { styled } from '@mui/material';

import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export const StyledDropzone = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0rem',
    width: '100%',
    padding: 2,
    minHeight: '100%'
}));

export const StyledFileTree = () => {};

export const Field = styled(Box)<BoxProps>(() => ({
    border: '0',
    padding: '0.5rem 1.5rem',
    margin: '1em 2rem 0 1em',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4em'
}));

export const ResponsivePage = styled('div')({
    display: 'flex',
    width: '100%',
    height: '100%'

});

export const ResponsiveHeader = styled('div')({
    display: 'flex',
    position: 'relative'
});

export const ResponsiveFlex = styled('div')({
    display: 'flex',
    position: 'relative'
});

export const StyledUploadContainer = styled(Box)<BoxProps>(() => ({
    height: '7rem',
    padding: '0.5rem 1.5rem',
    margin: '0 2rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const StyledActionBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    justifyContent: 'end'
}));

export const StyledPathContainer = styled(Box)<BoxProps>(() => ({
    padding: '0.5rem 1.5rem'
}));

export const StyledPathLink = styled(Link)(({ theme }) => ({
    display: 'inline',
    marginRight: '1rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}));

export const StylePathLinkText = styled(Typography)<TypographyProps>(() => ({
    display: 'inline',
    color: 'black'
}));
